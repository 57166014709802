.article-section {
	padding-top: 0;
	position: relative;
	overflow: visible;
	z-index: 4;
	padding-bottom: ac(60px, 34px);

	&.policy-page {
		.content-element.article-type {
			width: 90%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			@mixin mob-lg {
				width: 100%;
			}
		}
	}

	.following-container {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;

		.content-container {
			width: 57.82%;
			padding-top: ac(70px, 40px);

			@mixin min-media 902 {
				.job-info-card {
					display: none;
				}
			}

			.content-element {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding-bottom: ac(30px, 24px);
			}

			@mixin desk-sm {
				width: 52%;
			}

			@mixin tab-md {
				width: 100%;

				.job-info-card {
					max-width: 600px;
					margin-bottom: 30px;

					@mixin mob-xl {
						max-width: 100%;
					}
				}
			}
		}

		.sidebar {
			width: 32.51%;
			flex-shrink: 0;

			.sidebar__buffer {
				display: block;
			}

			.sidebar__inner {
				position: sticky;
				padding-bottom: 70px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;

				@mixin tab-md {
					padding-bottom: 20px;
				}

				@mixin mob-xl {
					padding-bottom: 0;
				}
			}

			.job-info-card {
				margin-top: ac(-40px, -20px);
				width: 100%;

				&:not(:last-child) {
					margin-bottom: ac(80px, 40px);
				}

				@mixin tab-md {
					display: none;
				}
			}

			.job-similar-title {
				text-transform: uppercase;

				&:not(:last-child) {
					margin-bottom: ac(30px, 20px);
				}
			}

			@mixin desk-sm {
				width: 42%;
			}

			@mixin tab-md {
				padding-top: 30px;
				width: 100%;
			}
		}

		@mixin tab-md {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&.insight-detail-type {
		padding-top: 0;
		padding-bottom: ac(35px, 20px);
		margin-top: -2px;

		.following-container {
			.content-container {
				padding-top: 0;
				width: 62%;
				max-width: 794px;

				.content-element {
					padding-top: 0;

					h2 {
						max-width: 602px;
					}
				}

				.insight-detail-top-line {
					width: 59.19%;
				}

				@mixin tab-md {
					width: 100%;
				}
			}

			.sidebar {
				width: 36.72%;
				flex-shrink: 0;
				@mixin tab-md {
					width: 100%;

					.sidebar__inner {
						padding-bottom: 32px;
					}
				}
			}

			@mixin tab-md {
				flex-direction: column-reverse;

				.share-contacts-container.with-desk {
					display: none;
				}
			}
		}

		.share-contacts-container.with-mob {
			@mixin min-media 902 {
				display: none;
			}
		}

		.insight-detail-bottom-line {
			margin-top: ac(60px, 32px);
			width: 74.69%;
		}
	}

	@mixin tab-md {
		overflow: hidden;
	}

	+ .latest-jobs-section {
		overflow: hidden;
	}
}

.share,
.job-contact {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__title {
		font-family: var(--font-sk);
		font-size: ac(24px, 18px);
		font-weight: 400;
		line-height: 30px;
		text-transform: uppercase;
		margin-bottom: ac(20px, 18px);
	}
}

.share {
	&__socials {
		padding-top: ac(9px, 4px);
		gap: ac(60px, 20px);

		.social-link {
			font-size: ac(30px, 20px);
			display: flex;
			justify-content: center;
			align-items: center;

			@mixin min-media 1026 {
				.icon-facebook {
					transform: scale(1.1) translateX(-2px);
				}

				.icon-linkedin {
					transform: scale(1.2) translateX(-4px);
				}

				.icon-twitter {
					transform: translateX(-1px) scale(1.05) translateY(2px);
				}

				.icon-mail {
					transform: translateY(2px);
				}
			}
		}
	}
}

.article-section {
	&__share {
		padding-top: ac(34px, 28px);
	}

	&__share-title {
		font-size: ac(20px, 18px);
		font-weight: 600;
		line-height: ac(24px, 22px);
		font-family: var(--font-main);
	}

	&__socials {
		padding-top: ac(14px, 10px);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: ac(30px, 18px);

		.social-link {
			font-size: ac(30px, 20px);
			display: flex;
			justify-content: center;
			align-items: center;

			i {
				color: var(--red);
			}

			&:hover {
				i {
					color: var(--blue);
				}
			}

			@mixin min-media 1026 {
				.icon-facebook {
					transform: scale(1) translateX(-2px);
				}

				.icon-linkedin {
					transform: scale(1) translateX(-4px);
				}

				.icon-twitter {
					transform: translateX(-1px) scale(1) translateY(0);
				}

				.icon-mail {
					transform: translateY(0);
				}
			}
		}
	}
}

.job-contact {
	&__name {
		font-family: var(--font-space);
		font-size: ac(22px, 18px);
		font-weight: 400;
		line-height: 30px;

		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}

	&__socials {
		padding-top: 4px;
		gap: ac(30px, 16px);

		.social-link {
			font-size: ac(22px, 18px);
			display: flex;
			justify-content: center;
			align-items: center;
			transform: translateX(-2px);
		}
	}

	&__link {
		font-size: ac(18px, 16px);
		line-height: ac(28px, 26px);
		margin-bottom: 4px;
		max-width: 100%;
		word-wrap: break-word;
		overflow-wrap: break-word;
	}
}
