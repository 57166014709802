.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ac(15px, 12px);

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 16px;
		line-height: 1.2;
		cursor: pointer;

		a,
		span {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			transition: color 0.3s ease, text-decoration-color 0.3s ease;
			color: var(--dark-blue);
			text-decoration: underline;
			text-decoration-color: var(--transparent);
		}

		&.active {
			background: var(--dark-blue);
			border-radius: 4px;
			cursor: default;

			a {
				color: var(--white);
				min-width: 26px;
				padding: 0 4px;
				height: 26px;
			}
		}

		&:hover {
			&:not(.active) {
				a {
					color: var(--red);
					text-decoration-color: var(--red);
				}
			}
		}
	}
}

.slider-pagination {
	--swiper-pagination-color: var(--dark-blue);
	--swiper-pagination-bullet-size: 17px;
	--swiper-pagination-bullet-width: 17px;
	--swiper-pagination-bullet-height: 17px;
	--swiper-pagination-bullet-inactive-color: var(--white);
	--swiper-pagination-bullet-inactive-opacity: 1;
	--swiper-pagination-bullet-opacity: 1;
	--swiper-pagination-bullet-horizontal-gap: 5px;
	margin-left: auto;
	margin-right: auto;
	margin-top: ac(30px, 24px);
	display: flex;
	justify-content: center;
	align-items: center;

	.swiper-pagination-bullet {
		transition: background-color 0.3s ease;
		border-radius: 4px;
		border: 1px solid var(--dark-blue);

		&:hover {
			--swiper-pagination-bullet-inactive-color: var(--red);
		}
	}
}
