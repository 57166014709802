.recruitment-section {
	padding-top: ac(70px, 64px);
	padding-bottom: ac(70px, 64px);
	background: var(--dark-blue);
	overflow: hidden;

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__title {
		color: var(--white);
		max-width: ac(706px, 500px);

		&:not(:last-child) {
			margin-bottom: ac(42px, 24px);
		}

		@mixin mob-lg {
			max-width: 100%;
		}
	}

	&__content {
		padding-top: ac(42px, 24px);
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		.line-decor {
			position: absolute;
			top: 0;
			left: -7.64vw;
			width: ac(507px, 400px);

			&:not(:last-child) {
				margin-bottom: ac(40px, 24px);
			}

			@mixin min-media 1441 {
				left: calc((-1) * (100vw - 1221px) / 2);
			}

			@mixin mob-lg {
				width: 80%;
			}

			&:before {
				transition-delay: 0.2s;
			}
		}
	}

	&__text {
		max-width: ac(706px, 600px);

		&:not(:last-child) {
			margin-bottom: ac(56px, 28px);
		}

		@mixin mob-xl {
			max-width: 100%;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-column-gap: ac(118px, 28px);
		grid-row-gap: ac(39px, 18px);
		list-style-type: none;
		counter-reset: num;
		max-width: ac(1118px, 1000px);

		@mixin desk-sm {
			max-width: 100%;
		}

		@mixin mob-lg {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}

	&__list-item {
		background: var(--white);
		border-radius: 9px;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
		padding-left: ac(103px, 56px);
		padding-top: ac(30px, 28px);
		padding-right: ac(30px, 24px);
		padding-bottom: ac(30px, 28px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;

		&:before {
			position: absolute;
			color: var(--dark-blue);
			top: ac(34px, 28px);
			left: ac(30px, 16px);
			font-family: var(--font-main);
			font-weight: 800;
			font-size: ac(36px, 24px);
			line-height: 1;
			content: "0" counter(num);
			counter-increment: num;

			&:nth-child(n + 10) {
				&:before {
					content: counter(num);
				}
			}
		}
	}

	&__list-item-title {
		color: var(--red);

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	&__list-item-text {
		min-height: calc(ac(25px, 20px) * 2);
	}

	&.update-styles {
		background: var(--white);

		.recruitment-section__title {
			color: var(--dark-blue);
		}
	}
}
