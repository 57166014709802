/*
Styles for popup background and basic styles for fonts
*/

.termsfeed-com---nb .cc-nb-main-container {
  padding-bottom: ac(50px, 24px);
  padding-top: ac(50px, 24px);
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: none;
  border-right: 0;
  border-bottom: 0;
  background: var(--white);
  border-radius: 9px;
  color: var(--dark-blue);
  font-family: var(--font-main);
  box-shadow: 0px 4px 12px 0px color-mod(var(--red) a(20%));

  @mixin min-media 652 {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @mixin media 651 {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @mixin media 380 {
    margin-right: 0;
  }

  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    text-transform: uppercase;
  }

  .cc-nb-title,
  .cc-nb-text {
    color: var(--dark-blue);
  }

  #cc-nb-text {
    font-weight: 400;
  }
  #cc-nb-title {
    color: var(--red);
    font-family: var(--font-main);
  }
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    background: var(--red);
    padding: 10px 18px;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--red);
    border-radius: 9px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fit-content;
    z-index: 2;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: var(--white);
    text-transform: uppercase;
    font-family: var(--font-main);

    &:focus {
      box-shadow: none !important;
      border: 2px solid var(--white) !important;
    }

    &:after {
      position: absolute;
      inset: 0;
      content: "";
      background: var(--red);
      opacity: 1;
      border-radius: 0;
      transition: all 0.1s ease;
      z-index: -2;
    }

    &:before {
      position: absolute;
      inset: 0;
      content: "";
      background: var(--white);
      opacity: 0;
      border-radius: 100px;
      transition: all 0.3s ease;
      z-index: -1;
      transform: scale(0) rotate(14deg);
    }

    &:hover {
      background: transparent;
      color: var(--red);

      &:before {
        transform: scale(1) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .cc-nb-changep {
    background: var(--blue);
    border-color: var(--blue);

    &:after {
      background: var(--blue);
    }

    &:before {
      transform: scale(0) rotate(-14deg);
    }

    &:hover {
      background: transparent;
      color: var(--blue);
      &:before {
        transform: scale(1) rotate(0deg);
      }
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
  color: var(--red);
  transition: all 0.3s ease;
  text-transform: none;
  font: inherit;

  &:hover {
    color: var(--blue);
    text-decoration-color: var(--blue);
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: none;
    border-radius: 9px;
    overflow: hidden;
    box-shadow: 0px 4px 12px 0px color-mod(var(--red) a(20%));
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--white) !important;
    border-top-color: var(--dark-blue) !important;
    border-bottom-color: var(--dark-blue) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-body-tabs {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 3px;
    padding: 3px;
  }

  .cc-cp-body-tabs-item {
    background: var(--blue);
    transition: all .3s ease;
    opacity: 1;
    border: none !important;
    border-radius: 4px !important;

    &:hover {
      opacity: 0.8;
      .cc-cp-body-tabs-item-link {
        color: var(--white) !important;
      }
    }
  }

  .cc-cp-body-tabs-item-link {
    color: var(--white);
    transition: all .3s ease;

    &:focus {
      box-shadow: none;
    }


  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--red) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--white);
    }

    &:hover {
      opacity: 1;
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--dark-blue);
      padding: 5px;
      background: var(--light-grey);
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(32px, 28px);
        font-style: normal;
        font-family: var(--font-main);
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--red);
    transition: all 0.3s ease;

    &:hover {
      color: var(--black);
      transform: scale(1.1);
    }

    &:focus {
      border: 0;
    }
  }
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--red);
      text-decoration: underline;
      transition: all 0.3s ease;

      &:hover {
        color: var(--dark-blue);
        text-decoration-color: var(--dark-blue);
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    background: var(--red);
    padding: ac(18px, 16px) ac(30px, 24px);
    position: relative;
    overflow: hidden;
    border: 2px solid var(--red);
    border-radius: 9px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fit-content;
    z-index: 2;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: var(--white);
    text-transform: uppercase;
    font-family: var(--font-main);

    &:focus {
      box-shadow: none !important;
      border: 2px solid var(--white) !important;
    }

    &:after {
      position: absolute;
      inset: 0;
      content: "";
      background: var(--red);
      opacity: 1;
      border-radius: 0;
      transition: all 0.1s ease;
      z-index: -2;
    }

    &:before {
      position: absolute;
      inset: 0;
      content: "";
      background: var(--white);
      opacity: 0;
      border-radius: 100px;
      transition: all 0.3s ease;
      z-index: -1;
      transform: scale(0) rotate(14deg);
    }

    &:hover {
      background: transparent;
      color: var(--red);

      &:before {
        transform: scale(1) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }
    }

    @mixin media 530 {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
  }
}

.termsfeed-com---palette-dark {

  .cc-cp-body-content-entry-text {
    color: var(--dark-blue);
  }

  .cc-cp-body-content-entry-title {
    color: var(--dark-blue);
  }

  .cc-pc-head-title-text {
    color: var(--dark-blue);
  }

  .cc-pc-head-title-headline {
    color: var(--dark-blue);
  }

  .cc-cp-foot-byline {
    color: var(--dark-blue);
  }
}

.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox + label {
  color: var(--dark-blue);

  &:after, &:before {
    border-color: var(--dark-blue);
  }
}

.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox:checked + label::before {
  background: var(--red);
}

/*
Styles for mobile adaptation
*/
@mixin media 651 {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }
}
