.first-section {
	background: var(--blue);
	position: relative;
	overflow: hidden;

	&__btn-post {
		position: absolute;
		right: 0;
		top: 33.33%;
		z-index: 2;

		@mixin tab-sm {
			display: none;
		}

		&--mob {
			display: none;

			@mixin tab-sm {
				display: flex;
				position: relative;
				top: auto;
				right: auto;
				margin-left: auto;
				margin-right: auto;
				margin-top: 30px;
				min-width: 170px;
			}
		}

		&--second {
			display: flex;
			position: relative;
			top: auto;
			right: auto;
			margin-left: auto;
			margin-right: auto;
			margin-top: 30px;
			min-width: 170px;
		}
	}

	&__decor {
		@mixin aspect-ratio 916, 1043;
		width: 63.61%;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-47%);
		z-index: 1;

		@mixin min-media 1800 {
			width: 58%;
		}

		@mixin tab {
			right: auto;
			left: 40%;
			width: 70%;
			min-width: 680px;
		}

		@mixin tab-sm {
			left: 30%;
		}

		@mixin mob-xl {
			left: 20%;
		}
	}

	&__wrap {
		height: auto;
		min-height: 100vh;
		min-height: calc(var(--vh, 1vh) * 100);
		padding: ac(141px, 80px) 0 ac(106px, 60px);
		margin: 0 auto;
		max-width: ac(1221px, 880px);
		width: 100%;
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@media only screen and (max-height: 800px) {
			padding-top: ac(140px, 80px);
		}
	}

	&__title {
		margin-top: 20px;
		text-align: center;
		color: var(--white);
		max-width: ac(812px, 390px);
		&:not(:last-child) {
			margin-bottom: ac(64px, 32px);
		}

		@media only screen and (max-height: 800px) {
			margin-top: 0;
			&:not(:last-child) {
				margin-bottom: ac(44px, 32px);
			}
		}
	}

	&__text {
		margin-top: ac(-20px, -6px);
		max-width: ac(640px, 540px);
		text-align: center;

		&:not(:last-child) {
			margin-bottom: ac(54px, 28px);
		}

		@mixin mob-xl {
			max-width: 100%;
		}

		+ div .portal-navigation__bottom-text {
			margin-top: ac(-45px, -18px);
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		flex-wrap: wrap;
		gap: ac(30px, 16px);

		&:not(:last-child) {
			margin-bottom: ac(64px, 32px);
		}

		@media only screen and (max-height: 800px) {
			&:not(:last-child) {
				margin-bottom: ac(44px, 32px);
			}
		}
	}

	&__form {
		width: 100%;
		background: var(--white);
		border-radius: 9px;
		padding-top: ac(83px, 60px);
		padding-bottom: ac(50px, 40px);
		padding-left: ac(103px, 40px);
		padding-right: ac(103px, 40px);

		@mixin desk-sm {
			padding-left: 40px;
			padding-right: 40px;
		}

		@mixin media 1140 {
			padding-left: 20px;
			padding-right: 20px;
		}

		@mixin media 1000 {
			padding-top: ac(50px, 40px);
		}

		@mixin mob-xl {
			padding-left: 24px;
			padding-right: 24px;
		}

		@mixin mob-lg {
			padding-top: 40px;
		}

		@mixin mob-sm {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__logo {
		width: ac(294px, 200px);
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: ac(50px, 32px);

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__form-portal {
		width: 100%;
		background: var(--white);
		border-radius: 9px;
		padding-top: ac(50px, 32px);
		padding-bottom: ac(50px, 32px);
		padding-left: ac(103px, 24px);
		padding-right: ac(103px, 24px);
		max-width: ac(1015px, 640px);

		@mixin mob-sm {
			padding-left: 20px;
			padding-right: 20px;
		}

		.form__bottom {
			@mixin mob {
				.btn {
					width: 100%;
				}
			}
		}

		&.log-in-page {
			max-width: ac(603px, 480px);

			.form__grid {
				grid-template-columns: repeat(1, minmax(0, 1fr));
			}

			.form__bottom {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;

				.btn {
					margin-top: ac(5px, 0px);
					margin-left: 0;
				}
			}

			.form__bottom-text {
				margin-left: auto;
				margin-right: auto;
				width: auto;
			}

			.checkbox {
				width: 100%;
				.checkbox-text {
					flex: 1;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: flex-start;
					a {
						margin-left: auto;
						font-weight: 500;
					}
				}
			}

			@mixin mob-xl {
				max-width: 100%;
			}
		}

		@mixin mob-xl {
			max-width: 100%;
		}
	}

	&.log-in-type {
		.first-section__wrap {
			justify-content: center;
			padding-top: ac(168px, 98px);
			padding-bottom: ac(168px, 98px);
		}

		.first-section__title:not(:last-child) {
			margin-bottom: ac(56px, 32px);

			+ div .portal-navigation__bottom-text {
				margin-top: ac(-40px, -18px);
			}
		}
	}

	&.not-full-type {
		.first-section__wrap {
			height: auto;
			min-height: ac(484px, 450px);
			padding: ac(210px, 120px) 0 ac(70px, 60px);
			margin: 0 auto;
			max-width: ac(1221px, 880px);
			width: 100%;
			position: relative;
			z-index: 2;
		}

		.first-section__decor {
			top: -41px;
			transform: none;
		}

		.first-section__btn-post {
			top: ac(299px, 260px);

			@mixin min-media 769 {
				position: fixed;
				z-index: 100;
			}
		}

		&.job-page {
			.first-section__wrap {
				padding-top: ac(175px, 120px);
				/*padding-bottom: 64px;*/

				@mixin tab-md {
					min-height: auto;
				}
			}
		}

		&.employers-page {
			.first-section__wrap {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				@mixin media 901 {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
			}

			.first-section__content {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				width: 49%;

				@mixin media 901 {
					width: 100%;
					margin-bottom: 40px;
				}
			}

			.first-section__video {
				width: 48%;
				border-radius: 10px;
				overflow: hidden;
				clip-path: inset(0 0 0 0 round 10px);
				margin-right: 0;

				@mixin media 901 {
					width: 100%;
					max-width: 600px;
				}
			}

			.first-section__title {
				font-size: ac(54px, 36px);
			}

			.first-section__text {
				text-align: left;
			}
			.first-section__btn-post--second {
				margin-top: 0;
				position: relative;
				top: auto;
				margin-left: 0;
			}
		}
	}

	&.not-centered-type {
		.first-section__wrap {
			align-items: flex-start;
		}

		.first-section__title {
			text-align: left;
		}

		.first-section__btn-post--mob {
			@mixin mob-lg {
				margin-left: 0;
			}
		}
	}

	&.blogs-page {
		overflow: visible;

		.first-section__wrap {
			padding-top: ac(198px, 120px);
			padding-bottom: 28px;
			min-height: auto;
		}

		&.not-full-type {
			.first-section__title {
				max-width: ac(1015px, 920px);

				@mixin tab {
					max-width: 95%;
				}

				@mixin tab-sm {
					max-width: 100%;
				}
			}
		}
	}
}
