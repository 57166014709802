.job-alerts-section {
	padding-top: ac(60px, 32px);
	padding-bottom: ac(60px, 32px);

	&:last-child {
		padding-bottom: ac(170px, 60px);
	}

	&__form {
		width: 100%;
		margin: 0 auto;
		max-width: ac(1221px, 1200px);
		background: var(--dark-blue);
		border-radius: 9px;
		padding: 55px ac(206px, 80px) 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		@mixin desk-sm {
			max-width: 100%;
		}

		@mixin mob-xl {
			padding: 45px 24px 40px;
		}
	}

	&__title {
		line-height: 1;
		text-align: center;

		&:not(:last-child) {
			margin-bottom: ac(55px, 24px);
		}

		@mixin mob-xl {
			line-height: 120%;
		}
	}

	&__form-container {
		background: var(--white);
		padding: 4px;
		border-radius: 9px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.default-input {
			flex: 1;

			input {
				border: none;
			}
		}

		.btn {
			min-height: 41px;
			min-width: 80px;
			padding: 7px 10px;
		}
	}
}
