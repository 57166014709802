.jobs-slider {
	max-width: 100%;
	width: 100%;
	overflow: hidden;
	transition: opacity 0.3s ease 0.1s;
	opacity: 0;
	&.swiper-initialized {
		opacity: 1;
		overflow: visible;
	}
}
