.testimonials-section {
	position: relative;
	z-index: 2;
	overflow: hidden;
	padding-top: 29px;
	padding-bottom: ac(199px, 64px);

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		z-index: 1;

		@mixin tab-sm {
			padding-bottom: 230px;
		}
	}

	&__slider-container {
		width: 57.82%;
		background: var(--blue);
		border-radius: 9px;
		padding-top: ac(80px, 40px);
		padding-left: ac(80px, 30px);
		padding-bottom: ac(70px, 30px);
		padding-right: ac(168px, 64px);

		.testimonials-slider {
			width: 100%;
		}

		@mixin tab-sm {
			padding-bottom: 60px;
			width: 100%;
		}

		@mixin mob {
			padding-right: 30px;
		}
	}

	&__image {
		position: absolute;
		top: -29px;
		bottom: -29px;
		right: -7.64vw;
		left: 50.61%;
		border-radius: 9px 0 0 9px;
		clip-path: inset(0 0 0 0 round 9px 0 0 9px);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@mixin min-media 1441 {
			right: calc((-1) * (100vw - 1221px) / 2);
		}

		@mixin tab-sm {
			left: 10%;
			top: auto;
			bottom: -240px;
			height: 250px;
			transform: translateY(-100%);
		}
	}
}
