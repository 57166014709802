.footer {
	padding-top: ac(80px, 40px);
	padding-bottom: ac(40px, 32px);
	background: var(--blue);
	position: relative;
	z-index: 10;

	&.dark-type {
		background: var(--dark-blue);
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@mixin mob-xl {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 49%;

		@mixin mob-xl {
			width: 100%;
			margin-bottom: 24px;
		}
	}

	&__logo {
		@mixin aspect-ratio 191, 39.69;
		width: ac(191px, 150px);
		cursor: pointer;
		transition: transform 0.3s ease;

		img {
			object-fit: contain;
			object-position: left;
		}

		&:hover {
			transform: scale(1.05);
		}

		&:not(:last-child) {
			margin-bottom: ac(50.3px, 24px);
		}
	}

	&__links {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		&:not(:last-child) {
			margin-bottom: ac(20px, 16px);
		}
	}

	&__link-container {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 6px;

		p {
			font-size: 14px;
			line-height: 1;
			font-weight: 400;
		}

		&:not(:last-child) {
			margin-bottom: ac(14px, 8px);
		}

		@mixin media 374 {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__link {
		font-size: 14px;
		line-height: 1;
		font-weight: 500;
		transition: color 0.3s ease;
		position: relative;

		&:before {
			right: 0;
			bottom: -4px;
			height: 1px;
			width: 0;
			background: var(--white);
			content: "";
			position: absolute;
			transition: width 0.4s ease, background-color 0.3s ease;
		}

		&:hover {
			color: var(--red);
			&:before {
				background-color: var(--red);
				width: 100%;
				left: 0;
				right: auto;
			}
		}

		&.with-not-link {
			&:before {
				content: none;
			}

			&:hover {
				color: var(--white);
			}
		}
	}

	&__socials {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 10px;
	}

	&__social {
		font-size: 24px;
		transition: color 0.3s ease;
		color: var(--white);
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			color: var(--red);
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-start;
		width: 49%;

		@mixin mob-xl {
			width: 100%;
			align-items: flex-start;
		}
	}

	&__info-text {
		font-size: 12px;
		line-height: 1;

		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	&__info-logo {
		width: 150px;
		transition: transform 0.3s ease;

		&:hover {
			transform: scale(1.05);
		}
	}
}
