@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?hu4rtv');
	src:  url('../fonts/icomoon.eot?hu4rtv#iefix') format('embedded-opentype'),
	url('../fonts/icomoon.ttf?hu4rtv') format('truetype'),
	url('../fonts/icomoon.woff?hu4rtv') format('woff'),
	url('../fonts/icomoon.svg?hu4rtv#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
	content: "\e910";
}
.icon-refresh:before {
	content: "\e911";
}
.icon-mail:before {
	content: "\e905";
}
.icon-facebook:before {
	content: "\e90f";
}
.icon-quote:before {
	content: "\e90e";
}
.icon-arrow-down:before {
	content: "\e900";
}
.icon-arrow-left:before {
	content: "\e901";
}
.icon-arrow-right:before {
	content: "\e902";
}
.icon-arrow-up:before {
	content: "\e903";
}
.icon-check:before {
	content: "\e904";
}
.icon-heart-fill:before {
	content: "\e906";
}
.icon-heart-line:before {
	content: "\e907";
}
.icon-instagram:before {
	content: "\e908";
}
.icon-linkedin:before {
	content: "\e909";
}
.icon-minus:before {
	content: "\e90a";
}
.icon-pen:before {
	content: "\e90b";
}
.icon-plus:before {
	content: "\e90c";
}
.icon-show:before {
	content: "\e90d";
}
