/* ----------------------- Common desktop header ----------------------- */
.header {
	position: absolute;
	transition: background ease-in-out 0.25s, height ease-in-out 0.25s;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	padding: ac(60px, 20px) 0 ac(20px, 10px);
	display: flex;
	align-items: center;

	@media only screen and (max-height: 740px) {
		padding-top: ac(40px, 20px);
	}

	&.absolute {
		position: absolute;
	}

	&.fixed {
		position: fixed;
	}

	&.scrolled {
		background: rgba(var(--black-rgb), 0.8);
		backdrop-filter: blur(10px);
		height: ac(100px, 60px);

		.logo {
			width: ac(130px, 60px);
		}
	}

	.logo {
		height: 100%;
		width: ac(284px, 200px);
		flex-shrink: 0;
		transition: transform 0.3s ease;
		position: relative;
		z-index: 1;

		&::before {
			display: none;
		}

		&:hover {
			transition: transform 0.3s ease;
			transform: scale(1.03);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		@mixin media 1400 {
			@mixin min-media 1301 {
				width: 200px;
			}
		}

		@mixin media 1300 {
			@mixin min-media 1025 {
				width: 180px;
			}
		}
	}

	.navbar {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;
	}

	.navbar-nav {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		position: relative;
		margin-right: 15px;
		margin-left: auto;

		.menu {
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.btn {
			display: none;
		}

		span {
			&:not(.btn-text) {
				position: absolute;
				background: color-mod(var(--red) a(60%));
				border-radius: 9px;
				height: 37px;
				z-index: -1;
				opacity: 0;
				left: var(--left-position-span);
				width: var(--width-span);
				transition: var(--span-transition);

				&.active {
					opacity: 1;
				}
			}
		}

		.menu-link {
			padding: 10px ac(10px, 6px);

			&.with-border {
				@media (--mobile-menu-end-point) {
					padding: 10px ac(16px, 10px);
					margin: 0 ac(15px, 10px);
					margin-right: 0;
					border: 1px solid var(--white);
					border-radius: 9px;
					height: 37px;

					&:last-child {
						margin-right: 0;
					}
				}
			}

			@mixin media 1210 {
				@mixin min-media 1025 {
					padding: 10px 10px;
				}
			}
		}
	}

	.btn {
		padding: 8px ac(16px, 12px);

		+ .btn {
			margin-left: 10px;
		}

		@mixin media 850 {
			margin-left: auto;
		}

		@mixin media 901 {
			display: none;
		}
	}

	.navbar-close {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;

		@mixin media 850 {
			display: flex;
		}
	}

	.burger {
		display: none;
		margin-left: ac(50px, 20px);
		z-index: 1;

		@mixin media 850 {
			display: flex;
		}

		@mixin media 650 {
			margin-left: auto;
		}
	}

	/*.navbar-nav {
    display: flex;
    flex-grow: 1;
  }*/

	.menu {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	/*.menu-item {
    margin-right: 20px;
    position: relative;

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: "\e999";
          font-family: "icomoon";
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }*/

	&:not(.header-mobile) {
		.menu-item {
			@media (--mobile-menu-end-point) {
				padding: 5px 0;
			}

			&:hover {
				@media (--mobile-menu-end-point) {
					.dropdown-menu {
						display: block;
					}
				}
			}
		}
	}

	.menu-link {
		color: var(--white);
		position: relative;
		font-family: var(--font-main);
		text-transform: uppercase;
		font-size: 14px;
		line-height: 17px;
		font-weight: 700;
		@mixin transition-all;
		cursor: pointer;

		/*&::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--white);
      @mixin transition-all;
    }

    &:hover {
      color: var(--primary);

      &::before {
        width: 100%;
        left: 0;
        background: var(--primary);
      }
    }*/

		@mixin media 1210 {
			@mixin min-media 1025 {
				font-size: 12px;
			}
		}
	}

	.dropdown-menu {
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 100%;
		padding: 10px 10px 10px 15px;
		border-radius: 10px;
		background: var(--bg-second);
		box-sizing: content-box;
		display: none;

		li {
			.menu-link {
				padding: 5px 0;
				text-transform: capitalize;
			}
		}
	}

	@mixin media 1300 {
		@mixin min-media 1025 {
			.menu-link {
				font-size: 12px;
			}

			.btn span {
				font-size: 12px;
			}
		}
	}
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
	.header:not(.header-mobile) {
		.navbar {
			justify-content: flex-end;
		}

		.logo {
			margin-right: auto;
		}

		.burger,
		.menu-toggle {
			display: flex;
		}

		.navbar-nav {
			position: fixed;
			right: 0;
			top: 0;
			width: 60%;
			height: auto;
			min-width: 320px;
			padding-top: ac(300px, 110px);
			background: var(--blue);
			padding-right: 5px;
			padding-bottom: ac(190px, 90px);
			transition: transform 0.3s ease-out;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			margin-right: 0;
			border-bottom-left-radius: 40px;

			@mixin media 901 {
				.btn {
					margin-top: auto;
					display: flex;
					margin-left: 0;
					width: calc(100% - 18px);
					height: 40px;

					+ .btn {
						margin-top: 15px;
					}
				}
			}

			@mixin mob-xl {
				width: 100%;
				border-bottom-left-radius: 0;
				height: 100vh;
				height: calc(var(--vh, 1vh) * 100);
				padding-top: 200px;
			}

			&:before {
				content: "";
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				background-image: url("../images/decors/big-decor-pink.svg");
				background-size: contain;
				background-position: bottom right;
				background-repeat: no-repeat;
			}

			&.nav-slide-left {
				right: auto;
				left: 0;
				transform: translateX(-100%);

				&.active {
					transform: translateX(0);
				}
			}

			&.nav-slide-right {
				right: 0;
				left: auto;
				transform: translateX(100%);

				&.active {
					transform: translateX(0);
				}
			}

			&.nav-slide-down {
				right: auto;
				left: 0;
				transform: translateY(-100%);

				&.active {
					transform: translateY(0);
				}
			}

			&.nav-slide-up {
				right: auto;
				left: 0;
				transform: translateY(100%);

				&.active {
					transform: translateY(0);
				}
			}

			.menu {
				width: 100%;
				max-height: 90vh;
				flex-direction: column;
				justify-content: flex-start;
				padding-left: 40px;
				align-items: flex-start;
				align-self: center;
				position: relative;
				z-index: 1;

				&::-webkit-scrollbar {
					width: 3px;
					height: 2px;
					background: rgba(255, 255, 255, 0.1);
					border-radius: 3px;
				}

				&::-webkit-scrollbar-thumb {
					background: rgba(255, 255, 255, 0.5);
					border-radius: 3px;
					cursor: pointer;
				}

				@mixin mob-xl {
					flex: 1;
					padding-left: 24px;
				}
			}

			.menu-item {
				&:not(:last-child) {
					margin-bottom: 4px;
				}
			}

			.menu-link {
				padding: 2px 0;
				font-size: ac(30px, 22px);
				position: relative;

				&:not(:last-child) {
					margin-bottom: ac(24px, 16px);
				}

				&:before {
					position: absolute;
					right: 0;
					width: 0;
					bottom: -4px;
					height: 2px;
					background: var(--red);
					content: "";
					transition: all 0.4s ease;
				}

				&:hover {
					&:before {
						left: 0;
						right: auto;
						width: 100%;
					}
				}

				&.with-border {
					&:before {
						width: 100%;
						background-color: var(--white);
					}
				}
			}

			.header-socials {
				margin-top: auto;
				display: flex;
				align-items: center;
				gap: 16px;
				padding-left: 40px;

				li {
					a {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 40px;
						color: var(--red);
						filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.08));
						transition: color 0.25s ease;

						&:hover {
							color: var(--main);
						}
					}
				}
			}

			@mixin mob-lg {
				width: 100%;
			}
		}

		.dropdown-menu {
			position: relative;
			top: 0;
		}

		.menu-action {
			margin-right: 24px;
		}
	}
}

/* end of Common desktop header Mobile HEADER */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.header.header-mobile {
	.burger {
		display: flex;
	}

	.navbar-nav {
		position: fixed;
		right: 0;
		top: 0;
		width: 50%;
		height: 100vh;
		min-width: 320px;
		padding-top: 100px;
		background: var(--bg-second);
		padding-right: 5px;
		padding-bottom: 40px;
		transition: transform 0.3s ease-out;

		&.nav-slide-left {
			right: auto;
			left: 0;
			transform: translateX(-100%);

			&.active {
				transform: translateX(0);
			}
		}

		&.nav-slide-right {
			right: 0;
			left: auto;
			transform: translateX(100%);

			&.active {
				transform: translateX(0);
			}
		}

		&.nav-slide-down {
			right: auto;
			left: 0;
			transform: translateY(-100%);

			&.active {
				transform: translateY(0);
			}
		}

		&.nav-slide-up {
			right: auto;
			left: 0;
			transform: translateY(100%);

			&.active {
				transform: translateY(0);
			}
		}

		.menu {
			width: 100%;
			max-height: 100%;
			overflow-y: auto;
			flex-direction: column;
			justify-content: flex-start;
			padding-left: 40px;
			align-items: flex-start;
			align-self: center;

			&::-webkit-scrollbar {
				width: 3px;
				height: 2px;
				background: rgba(255, 255, 255, 0.1);
				border-radius: 3px;
			}

			&::-webkit-scrollbar-thumb {
				background: rgba(255, 255, 255, 0.5);
				border-radius: 3px;
				cursor: pointer;
			}
		}

		.menu-link {
			padding: 10px 0;
			font-size: ac(25px, 18px);
		}
	}

	.dropdown-menu {
		position: relative;
		top: 0;
	}
}

/* end of ONLY Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	transition: transform 400ms;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transform: scale(1.5);
	width: ac(60px, 40px) !important;
	&.active {
		transform: rotate(45deg) scale(1.5);

		.top {
			stroke-dashoffset: -68px;
		}

		.bottom {
			stroke-dashoffset: -68px;
		}

		.line {
			stroke: var(--white);
		}
	}

	.line {
		fill: none;
		transition: stroke 0.3s ease, stroke-dasharray 400ms,
			stroke-dashoffset 400ms;
		stroke: var(--white);
		stroke-width: 3.5;
		stroke-linecap: round;
	}

	.top {
		stroke-dasharray: 40 121;
	}

	.bottom {
		stroke-dasharray: 40 121;
	}

	&:hover {
		.line {
			@mixin min-media 1026 {
				stroke: var(--red);
			}
		}
	}

	&.active {
		&:hover {
			.line {
				@mixin min-media 1026 {
					stroke: var(--red);
				}
			}
		}
	}
}

.header-close-wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 77;
	background: rgba(0, 0, 0, 0.4);
	pointer-events: none;
	visibility: hidden;
	opacity: 0;

	@mixin transition-all;

	&.active {
		visibility: visible;
		pointer-events: auto;
		opacity: 1;
		cursor: pointer;
	}
}

/* end of HEADER COMPONENTS */
