.blogs-section {
    padding-top: 28px;
    padding-bottom: ac(140px, 80px);
    background: var(--blue);
    min-height: calc(100vh - 420px);

    &:before {
        inset: 0;
        position: absolute;
        z-index: 3;
        content: "";
        background: linear-gradient(180deg, rgba(36, 56, 80, 0.00) 0%, #243850 70%, #243850 100%);
    }

    &__wrap {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        position: relative;
        z-index: 4;
    }

    &__list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: ac(50px, 28px);

        @mixin tab-sm {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @mixin media 480 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .pagination {
        margin-top: ac(60px, 32px);
        margin-bottom: -26px;

        &__item {
            a,
            span {
                color: var(--white);
            }

            &.active {
                background: var(--dark-blue);

                a {
                    color: var(--white);
                }
            }

            &:hover {
                &:not(.active) {
                    a {
                        color: var(--red);
                        text-decoration-color: var(--red);
                    }
                }
            }
        }
    }
}

.blog-article-section {
    margin-top: ac(-5px, 0px);
    padding-top: 0;
    padding-bottom: ac(120px, 80px);
    background: var(--blue);

    &:before {
        inset: 0;
        position: absolute;
        z-index: 3;
        content: "";
        background: linear-gradient(180deg, rgba(36, 56, 80, 0.00) 0%, color-mod(var(--blue) a(70%)) 20%, #243850 55%, #243850 100%);
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: ac(1015px, 920px);
        position: relative;
        z-index: 4;

        @mixin tab {
            max-width: 95%;
        }

        @mixin tab-sm {
            max-width: 100%;
        }
    }

    &__top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        grid-column-gap: 40px;
        grid-row-gap: 20px;

        &:not(:last-child) {
            margin-bottom: ac(28px, 24px);
        }
    }

    &__date {
        font-size: ac(16px, 14px);
        font-weight: 500;
        line-height: normal;
        color: var(--white);
        font-family: var(--font-main);
    }

    &__image {
        width: 100%;
        @mixin aspect-ratio 1015, 460;
        border-radius: 9px;

        &:not(:last-child) {
            margin-bottom: ac(50px, 32px);
        }

        @mixin mob-lg {
            @mixin aspect-ratio 1015, 560;
        }
    }

    &__article {
        max-width: ac(912px, 800px);

        @mixin tab {
            max-width: 95%;
        }

        @mixin mob-lg {
            max-width: 100%;
        }

        &:not(:last-child) {
            margin-bottom: ac(50px, 32px);
        }

        h3:not(:first-child) {
            padding-top: ac(26px, 20px);
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__bottom-title {
        margin-bottom: 20px;
    }
}