.testimonials-slider {
	max-width: 100%;
	width: 100%;
	overflow: hidden;
	transition: opacity 0.3s ease 0.1s;
	opacity: 0;
	&.swiper-initialized {
		opacity: 1;
		overflow: visible;
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		height: auto;
		padding-right: 4px;

		i.icon-quote {
			color: var(--red);
			font-size: 28px;
			margin-bottom: ac(40px, 24px);
		}
	}

	&__item-text {
		width: 100%;
		max-height: calc(ac(29px, 22px) * 8);

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(4px);

			.simplebar-scrollbar {
				background: var(--grey);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}

	&__item-name {
		margin-top: ac(68px, 32px);
		text-transform: uppercase;
	}
}
