.promote-section {
	padding-top: ac(70px, 64px);
	padding-bottom: ac(140px, 64px);
	position: relative;
	overflow: visible;
	clip-path: inset(-500px 0 0 0);

	&__decor {
		@mixin aspect-ratio 1196, 1361;
		width: 83.06%;
		position: absolute;
		top: ac(-225px, -50px);
		right: -23.19%;
		opacity: 0.03;
		min-width: 800px;
		max-width: 1380px;
	}

	&__wrap {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-column-gap: 15px;
		grid-row-gap: 15px;
		position: relative;
		z-index: 2;

		@mixin media 951 {
			grid-template-columns: repeat(1, minmax(0, 1fr));
			max-width: 600px;
			margin: 0 auto;
		}

		@mixin mob-xl {
			max-width: 100%;
		}

		&.with-only-item {
			display: flex;
			justify-content: center;
			align-items: center;

			.promote-section__item {
				width: 100%;
				max-width: ac(603px, 500px);

				@mixin mob-lg {
					max-width: 100%;
				}
			}
		}
	}

	&__item {
		background: var(--dark-blue);
		border-radius: 9px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		padding: ac(40px, 28px);

		&:nth-child(2) {
			.line-decor {
				&:before {
					@mixin min-media 952 {
						transition-delay: 0.3s;
					}
				}
			}
		}
	}

	&__item-title {
		text-align: center;
		&:not(:last-child) {
			margin-bottom: ac(42px, 24px);
		}
	}

	.line-decor {
		width: 68.52%;

		&:not(:last-child) {
			margin-bottom: ac(40px, 24px);
		}
	}

	&__item-text {
		text-align: center;
	}

	&__item-buy {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: ac(40px, 24px);

		&:not(:last-child) {
			margin-bottom: ac(32px, 20px);
		}

		.btn {
			min-height: 41px;
		}
	}

	&__item-sum {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: ac(30px, 18px);
		text-transform: uppercase;
	}

	&__item-sum-old {
		font-weight: 500;
		text-transform: uppercase;

		span {
			font-weight: 400;
		}
	}

	&__item-sum-new {
		font-size: ac(30px, 22px);
		font-weight: 700;
		line-height: 120%;

		del {
			font-weight: 400;
		}
	}
}
