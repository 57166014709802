.progress-section {
	padding-top: ac(70px, 64px);
	padding-bottom: ac(70px, 64px);
	background: var(--red);
	overflow: hidden;

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__title {
		color: var(--white);

		&:not(:last-child) {
			margin-bottom: ac(40px, 24px);
		}
	}

	&__content {
		padding-top: ac(58px, 24px);
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		.line-decor {
			position: absolute;
			top: 0;
			left: -7.64vw;
			width: ac(507px, 400px);

			&:not(:last-child) {
				margin-bottom: ac(40px, 24px);
			}

			@mixin min-media 1441 {
				left: calc((-1) * (100vw - 1221px) / 2);
			}

			@mixin mob-lg {
				width: 80%;
			}

			&:before {
				transition-delay: 0.2s;
			}
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-column-gap: 15px;
		grid-row-gap: 15px;
		list-style-type: none;
		counter-reset: num;
		max-width: ac(912px, 700px);
		margin: 0 auto;

		@mixin tab-sm {
			max-width: 100%;
		}

		@mixin mob-xl {
			grid-template-columns: repeat(1, minmax(0, 1fr));
			max-width: 320px;
		}

		@mixin mob {
			max-width: 95%;
		}

		@mixin mob-sm {
			max-width: 100%;
		}
	}

	&__list-item {
		background: var(--blue);
		border-radius: 9px;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
		padding: ac(32px, 24px) ac(30px, 24px) ac(30px, 24px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;

		&:before {
			position: relative;
			color: var(--white);
			font-family: var(--font-main);
			font-size: ac(20px, 18px);
			font-weight: 600;
			line-height: 1;
			content: counter(num);
			counter-increment: num;
			margin-bottom: ac(18px, 16px);
		}
	}

	&__list-item-title {
		color: var(--white);

		&:not(:last-child) {
			margin-bottom: ac(18px, 16px);
		}
	}

	&__list-item-text {
	}

	&.update-styles {
		background: var(--dark-blue);
	}
}
