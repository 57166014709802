html {
	font-size: 16px;
	scrollbar-width: thin;
	scrollbar-color: var(--dark-blue) darkgray;
}

[data-aos="progress-anim"] {
	opacity: 0;
	transition: transform 0.3s ease-in-out, opacity 0.5s ease;

	&.aos-animate {
		opacity: 1;
		transform: translateX(0);
	}
}

.fp-watermark {
	display: none;
}

body {
	position: relative;
	min-width: 320px;
	margin: auto;
	background: var(--white);
	color: var(--black);

	overflow-x: hidden;

	&.disabled {
		overflow-y: scroll;
		position: fixed;

		width: 100%;
	}

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: darkgray;
		border-radius: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--dark-blue);
		border-radius: 0;
	}
}

.swiper-wrapper {
	left: 0 !important;
}

#site {
	position: relative;
	//overflow: hidden; min-height: 100%; display: flex;
	flex-direction: column;

	&:not(.is-visible) {
		overflow: hidden;
	}
}

img {
	transition: opacity 0.1s ease;
	&.lazyload {
		opacity: 0;
	}

	&.lazyloaded {
		opacity: 1;
	}
}

.main {
	flex-grow: 1;
}

% default-transition {
	transition: ease-in-out 0.25s;
}

.cont {
	margin: 0 auto;
	max-width: 1221px;
	width: perc(1221);

	@mixin tab-md {
		width: 90%;
	}

	@mixin mob-lg {
		width: 91%;
	}
}

section {
	position: relative;
	overflow-x: hidden;
}

.line-decor {
	height: 2px;
	position: relative;
	display: flex;

	&:before {
		content: "";
		background: var(--dark-blue);
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		transition: width 0.6s ease 0.1s, background-color 0.25s ease 0.1s,
			opacity 0.25s ease 0.1s;
		opacity: 0;
		width: 0;
	}

	&.red-line {
		&:before {
			background: var(--red);
		}
	}

	&.animation {
		&:before {
			width: 100%;
			opacity: 1;
		}
	}
}

.choices__list--dropdown .choices__item--selectable::after, .choices__list[aria-expanded] .choices__item--selectable::after {
	display: none;
}

#bold-credits {
	width: 150px;
	transition: ease-in-out 0.25s;

	&::before,
	&::after {
		display: none;
	}

	&:hover {
		transform: scale(1.1);
	}
}

#preview_image {
	object-fit: contain;

	img {
		object-fit: contain;
	}
}

:root {
	--plyr-color-main: var(--red);
	--plyr-video-control-color: var(--white);

	/*--plyr-control-spacing: ac(76px, 44px);*/
	@mixin media 375 {
		--plyr-control-spacing: 6px;
	}
}

.plyr__control--overlaid {
	background: var(--red) !important;
	border: 2px solid var(--red) !important;
	transform: scaleX(1) translateX(-48%) translateY(-50%);
	padding: 4px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	width: ac(56px, 40px) !important;
	height: ac(56px, 40px);
	opacity: 1 !important;

	&:hover {
		background-color: var(--blue) !important;
	}

	--plyr-control-icon-size: ac(18px, 16px);

	svg {
		left: 1px;
	}
}

.plyr-hide-controls {
	.plyr__controls {
		opacity: 0;
	}
}

.plyr__video-wrapper {
	z-index: 0;
}

.plyr__poster {
	background-size: cover;
}

@media (min-width: 480px) {
	.plyr--video .plyr__controls {
		padding-bottom: 20px;
	}
}

@mixin media 390 {
	.plyr__volume input[type='range'] {
		max-width: 60px;
	}
}

.pay-popup {
	border-radius: ac(12px, 8px);

	.popup__container {
		> .popup__title {
			display: none;
		}
	}

	.popup__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.popup-form__container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		&:not(:last-child) {
			margin-bottom: ac(24px, 20px);
		}
	}

	.popup__title {
		&:not(:last-child) {
			margin-bottom: ac(32px, 24px);
		}
	}

	.input__label {
		&:not(:last-child) {
			margin-bottom: ac(14px, 12px);
		}
	}

	.counter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 6px;
		border: 1px solid var(--red);
		height: 44px;
		max-height: 44px;
		width: 128px;
		overflow: hidden;
		background: var(--white);
		position: relative;
		padding-left: 1px;
		padding-right: 1px;
		gap: 0;

		/* Chrome, Safari, Edge, Opera */
		input[type='number']::-webkit-outer-spin-button,
		input[type='number']::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type='number'] {
			/*-moz-appearance: textfield;*/
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}

		input[type='number'] {
			-webkit-appearance: textfield;
			-moz-appearance: textfield;
			appearance: textfield;
		}
		input[type='number']::-webkit-inner-spin-button,
		input[type='number']::-webkit-outer-spin-button {
			-webkit-appearance: none;
		}

		input[type='number']::-webkit-inner-spin-button {
			opacity: 0;
			pointer-events: none;
			display: none;
		}

		.counter-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			color: var(--white);
			line-height: 1;
			transition: color 0.25s ease, background-color 0.25s ease;
			cursor: pointer;
			height: 42px;
			width: 42px;
			min-width: 42px;
			border-radius: 6px;
			border: 1px solid transparent;
			background: var(--white);

			i {
				background: transparent !important;
				font-size: 20px !important;
				color: var(--dark-blue) !important;
			}

			&:hover {
				background-color: var(--blue);
				color: var(--white) !important;

				i {
					color: var(--white) !important;
				}
			}
		}

		input {
			padding: 5px 0;
			font-family: var(--font-main);
			font-weight: 600;
			font-size: 16px;
			line-height: 120%;
			@mixin transparent-bg-input var(--dark-blue);
			width: 40px;
			height: 40px;
			text-align: center;
			color: var(--dark-blue);
		}

		/*&.counter-small {
      height: 32px;
      border-radius: 6px;

      .counter-btn {
        font-size: 32px;
      }

      input {
        width: 32px;
        height: 32px;
      }
    }*/

		[role='status'] {
			svg {
				fill: var(--dark-blue);
				color: color-mod(var(--dark-blue) a(40%));
			}
		}

		&.disabled {
			opacity: 0.8;
			cursor: not-allowed;
		}

		/*&.counter-input-num {
      border-radius: 6px;
      border: 1px solid var(--cl-lilac-450);
      height: 44px;
      max-height: 44px;
      width: 74px;
      background: var(--cl-lilac-50);
      padding-left: 10px;

      input {
        padding: 0;
        width: 32px;
        @mixin transparent-bg-input var(--cl-primary);
        background: transparent;
        text-align: left;
        color: var(--cl-primary);
        letter-spacing: -0.04em;
        font-size: 17px;
      }

      .counter-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 7px;
        color: var(--cl-lilac-500);
        line-height: 1;
        height: 22px;
        width: 22px;
        border-radius: 0;
        border: none;
        background: transparent;
        position: absolute;
        right: 8px;

        &.counter-btn-minus {
          bottom: 0;
        }

        &.counter-btn-plus {
          top: 0;

          i {
            transform: scaleY(-1);
          }
        }

        &:hover {
          background-color: transparent;
          color: var(--cl-lilac-950);
          filter: drop-shadow(0 0 1px var(--cl-lilac-500));
        }
      }
    }*/
	}
}

