.job-card {
	padding: 26px 30px 20px ac(180px, 140px);
	border-radius: 9px;
	background: var(--white);
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
	position: relative;
	transition: box-shadow 0.3s ease;
	height: auto;
	min-height: ac(180px, 140px);

	&__favorite {
		position: absolute;
		z-index: 2;
		cursor: pointer;
		top: 30px;
		right: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 22px;
		height: 19px;
	}

	&__favorite-btn {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 1;

		i {
			font-size: 19px;
			color: var(--dark-blue);
			transition: all 0.3s ease;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		i.icon-heart-fill {
			opacity: 0;
		}

		&.active {
			i {
				color: var(--red);
			}

			i.icon-heart-line {
				opacity: 0;
			}

			i.icon-heart-fill {
				opacity: 1;
			}
		}

		&:hover {
			i {
				color: var(--red);
				filter: drop-shadow(0px 0px 2px color-mod(var(--red) a(40%)));
			}

			i.icon-heart-fill {
				opacity: 0.5;
			}

			&.active {
				i {
					color: var(--blue);
				}

				i.icon-heart-line {
					opacity: 1;
				}

				i.icon-heart-fill {
					opacity: 0.5;
				}
			}
		}
	}

	&__logo {
		@mixin aspect-ratio 1, 1;
		position: absolute;
		top: 30px;
		left: 30px;
		width: ac(120px, 80px);

		img {
			object-fit: contain;
		}
	}

	&__title {
		color: var(--red);
		max-width: calc(100% - 30px);

		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	&__company {
		text-transform: uppercase;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&__additional {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		grid-row-gap: 10px;

		li {
			font-size: 16px;
			font-weight: 500;
			position: relative;
			padding-right: 15px;
			margin-right: 15px;
			line-height: 1.2;

			&:not(:last-child) {
				&:before {
					position: absolute;
					right: 0;
					height: 90%;
					bottom: 0;
					width: 1px;
					content: "";
					background: var(--dark-blue);
				}
			}
		}

		&:not(:last-child) {
			margin-bottom: 16px;
		}

		@mixin mob-sm {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			gap: 5px;

			li {
				width: 80%;
				padding-bottom: 5px;
				margin-right: 0;
				&:not(:last-child) {
					&:before {
						right: 0;
						height: 1px;
						bottom: 0;
						width: 100%;
					}
				}
			}
		}
	}

	&__bottom {
		width: 100%;
		min-height: 41px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
		gap: ac(30px, 20px);

		/*.link {
			margin-left: auto;
		}*/

		.btn {
			/*margin-left: ac(30px, 20px);*/
			min-height: 41px;
			min-width: 75px;
		}

		@mixin mob-sm {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			.link {
				margin-left: 0;
			}
		}
	}

	&__time {
		margin-top: ac(4px, 0px);
		font-size: 14px;
		font-weight: 400;
		color: var(--dark-blue);
		font-family: var(--font-main);
		margin-right: auto;
	}

	&:hover {
		box-shadow: 0px 4px 12px 0px color-mod(var(--red) a(20%));
	}

	&:container(width < 400px) {
		padding-left: 30px;
		padding-bottom: 25px;

		.job-card__favorite {
			top: 26px;
		}

		.job-card__logo {
			position: relative;
			top: auto;
			left: auto;
			margin-bottom: 25px;
			width: 100px;
		}

		.job-card__title {
			max-width: 100%;
		}

		.job-card__title:not(:last-child) {
			margin-bottom: 6px;
		}

		.job-card__company:not(:last-child) {
			margin-bottom: 18px;
		}

		.job-card__additional:not(:last-child) {
			margin-bottom: ac(25px, 20px);
		}

		.job-card__time {
			margin-top: 0;
		}
	}
}

.job-info-card {
	padding: 30px 30px 30px 30px;
	border-radius: 9px;
	background: var(--white);
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	&__left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 70px;
		margin-right: ac(25px, 16px);
	}

	&__favorite {
		z-index: 2;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 22px;
		height: 19px;
	}

	&__favorite-btn {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 1;

		i {
			font-size: 19px;
			color: var(--dark-blue);
			transition: all 0.3s ease;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		i.icon-heart-fill {
			opacity: 0;
		}

		&.active {
			i {
				color: var(--red);
			}

			i.icon-heart-line {
				opacity: 0;
			}

			i.icon-heart-fill {
				opacity: 1;
			}
		}

		&:hover {
			i {
				color: var(--red);
				filter: drop-shadow(0px 0px 2px color-mod(var(--red) a(40%)));
			}

			i.icon-heart-fill {
				opacity: 0.5;
			}

			&.active {
				i {
					color: var(--blue);
				}

				i.icon-heart-line {
					opacity: 1;
				}

				i.icon-heart-fill {
					opacity: 0.5;
				}
			}
		}
	}

	&__logo {
		@mixin aspect-ratio 1, 1;
		width: 70px;

		img {
			object-fit: contain;
		}

		&:not(:last-child) {
			margin-bottom: ac(28px, 20px);
		}
	}

	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__company {
		text-transform: uppercase;

		&:not(:last-child) {
			margin-bottom: 19px;
		}
	}

	&__additional {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		li {
			font-size: ac(20px, 18px);
			font-weight: 400;
			position: relative;
			line-height: 1.2;

			&:not(:last-child) {
				margin-bottom: 15px;
			}
		}

		&:not(:last-child) {
			margin-bottom: ac(40px, 24px);
		}
	}

	&__time {
		margin-top: ac(30px, 20px);
		font-size: 14px;
		font-weight: 400;
		color: var(--dark-blue);
		font-family: var(--font-main);
	}
}

/*
<a href="#" aria-label="job" class="job-card">
									<object class="job-card__favorite">
										<button class="job-card__favorite-btn">
											<i class="icon-heart-line"></i>
											<i class="icon-heart-fill"></i>
										</button>
									</object>
									<div class="job-card__logo">
										<picture>
											<source
												data-srcset="./images/logos/company-logo.webp"
												type="image/webp"
											/>
											<img
												class="lazyload"
												data-src="./images/logos/company-logo.png"
												alt="Company Logo"
											/>
										</picture>
									</div>
									<h3 class="job-card__title">Senior Construction Manager</h3>
									<h5 class="job-card__company">company name</h5>
									<ul class="job-card__additional">
										<li>Department</li>
										<li>Houston, TX</li>
										<li>Permanent</li>
										<li>$00,000</li>
									</ul>
									<object class="job-card__bottom">
										<time class="job-card__time">2 days ago</time>
										<a
											aria-label="link for see applications"
											href="#"
											class="link"
											>see applications</a
										>
										<a aria-label="edit application" href="#" class="btn"
											><span>Edit</span></a
										>
									</object>
</a>
*/
