.blog-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__image {
        width: 100%;
        @mixin aspect-ratio 397, 240;
        border-radius: 9px;

        img {
            transition: transform .3s ease;
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__date {
        font-size: ac(16px, 14px);
        font-weight: 500;
        line-height: normal;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__title {
        @mixin max-line-length 4;

        h3 {
            line-height: normal;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .btn {
        margin-top: auto;
    }

    &:hover {
        img {
            transform: scale(1.03);
        }
    }
}