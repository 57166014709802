.default-input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	label {
		font-family: var(--font-main);
		font-size: 14px;
		line-height: 17px;
		font-weight: 700;
		color: var(--dark-blue);
		padding-bottom: 8px;
		text-transform: uppercase;
	}

	input,
	textarea,
	select {
		width: 100%;
		font-size: 14px;
		line-height: 28px;
		font-weight: 500;
		font-family: var(--font-main);
		color: var(--dark-blue);
		border-width: 1px;
		border-style: solid;
		border-color: var(--dark-blue);
		border-radius: 9px;
		transition: all 0.3s ease;
		padding-left: ac(16px, 12px);
		padding-right: ac(16px, 12px);
		height: 42px;
		resize: none;
		background: transparent;
		@mixin transparent-bg-input var(--dark-blue);

		&::placeholder {
			color: var(--grey);
		}

		&:focus {
			border-color: var(--blue);
			caret-color: var(--red);
		}
	}

	textarea {
		padding-top: ac(13px, 10px);
		flex: 1;
		min-height: 117px;
		height: auto;
		line-height: 1.2;
		scrollbar-width: thin;
		scrollbar-color: var(--red) var(--transparent);
		overflow-x: hidden;
		overflow-y: auto;
		clip-path: inset(0 0 0 0 round 9px);
		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: var(--transparent);
			border-radius: 0;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--red);
			border-radius: 0;
		}
	}

	.cke_1.cke_chrome {
		border-color: var(--blue) !important;
		border-radius: 9px;
		overflow: hidden;
	}

	.cke_1 .cke_top {
		border-bottom-color: var(--blue) !important;
	}

	.cke_1 .cke_bottom {
		border-top-color: var(--blue) !important;
	}
}

.default-select {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	label {
		font-family: var(--font-main);
		font-size: 14px;
		line-height: 17px;
		font-weight: 700;
		color: var(--dark-blue);
		padding-bottom: 8px;
		text-transform: uppercase;
	}

	select {
		opacity: 0;
		height: 42px;
	}

	&.status-type {
		min-width: 150px;
		.choices__inner {
			background: transparent;
			padding-left: 10px;
			padding-right: 18px;
			padding-top: 2px !important;
			padding-bottom: 2px !important;
			height: 41px;
			min-height: 41px;
			border-width: 2px;
		}

		.choices[data-type*="select-one"] .choices__input {
			display: none;
		}
	}

	.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			background: var(--transparent);
			border-radius: 0;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--red);
			border-radius: 0;
		}
	}

	.choices-toggle {
		position: relative;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 42px;
		cursor: pointer;
		margin-top: -42px;
	}

	.choices {
		width: 100%;
		font-size: 14px;
		line-height: 28px;
		font-weight: 500;
		font-family: var(--font-main);
		color: var(--black);

		&:not(:last-child) {
			margin-bottom: 5px !important;
		}

		&[data-type*="select-multiple"] .choices__inner {
			cursor: pointer !important;
			pointer-events: none;
		}



		&__inner {
			border-width: 1px;
			border-style: solid;
			border-color: var(--dark-blue);
			border-radius: 9px;
			background: transparent;
			padding-left: ac(16px, 12px);
			padding-right: 20px;
			padding-top: 4px !important;
			padding-bottom: 4px !important;
			height: 42px;
			min-height: 42px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			@mixin max-line-length-one;
		}

		&__item {
			@mixin max-line-length-one;
		}

		&__placeholder {
			color: var(--grey);
			opacity: 1;
		}

		&__list {
			padding: 0;

			.choices__item {
				padding-right: ac(16px, 12px);

				&[data-value="All"] {
					font-weight: 600;
				}
			}

			&--multiple {
				color: var(--dark-blue);
				@mixin max-line-length-one;
				letter-spacing: -0.35px;

				.choices__item--selectable {
					display: none;
				}

				+ .choices__input {
					display: none;
				}
			}
		}



		&[data-type*="select-one"],
		&[data-type*="select-multiple"] {
			&:after {
				border: none;
				border-bottom: 1px solid var(--black);
				border-right: 1px solid var(--black);
				content: "";
				display: block;
				height: 5px;
				margin-top: -4px;
				pointer-events: none;
				position: absolute;
				right: 16px;
				top: 50%;
				transform-origin: 66% 66%;
				transform: rotate(45deg) scale(1.5);
				transition: all 0.15s ease-in-out;
				width: 5px;
			}
		}

		&.is-open {
			&:after {
				transform: rotate(-135deg) scale(1.5);
			}
		}

		&__list--dropdown,
		.choices__list[aria-expanded] {
			border: 1px solid var(--dark-blue);
			z-index: 20;

			@mixin min-media 552 {
				width: auto;
				min-width: 100%;
				max-width: 200%;
			}
		}

		&__list--dropdown .choices__item--selectable.is-highlighted,
		&__list[aria-expanded] .choices__item--selectable.is-highlighted {
			background-color: transparent;

			&:not(.is-selected) {
				color: var(--dark-blue);
			}
		}
	}
	.choices[data-type*="select-one"] .choices__input {
		margin: 2px;
		max-width: calc(100% - 4px);
		border: 1px solid var(--dark-blue);
		border-radius: 9px;
	}

	.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
		max-height: 202px;
	}

	.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
		padding-top: 6px;
		padding-bottom: 6px;
	}



	&__list {
		display: none;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		max-width: 100%;
		grid-column-gap: 10px;
		grid-row-gap: 8px;

		&.is-visible {
			display: flex;
		}
	}

	&__list-item {
		position: relative;
		font-size: ac(16px, 14px);
		line-height: 1.2;
		font-weight: 500;
		font-family: var(--font-main);
		padding-right: 14px;
		cursor: default;
		color: var(--dark-blue);
		user-select: none;

		&:after {
			content: "";
			cursor: pointer;
			display: block;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			width: 10px;
			height: 10px;
			transition: background-color 0.25s ease, opacity 0.25s ease,
			transform 0.25s ease;
			opacity: 0.5;
			background: var(--dark-blue);
			clip-path: polygon(
							20% 0%,
							0% 20%,
							30% 50%,
							0% 80%,
							20% 100%,
							50% 70%,
							80% 100%,
							100% 80%,
							70% 50%,
							100% 20%,
							80% 0%,
							50% 30%
			);
		}

		&:hover {
			&:after {
				opacity: 1;
				transform: translateY(-50%) scale(1.15);
			}
		}
	}

	&__help,
	&__error {
		margin-top: 5px;
		font-family: var(--font-main);
		font-size: ac(14px, 12px);
		line-height: 1.2;
		font-weight: 600;
	}

	&__help {
		color: var(--dark-blue);
	}

	&__error {
		color: red;
	}
}

.first-form-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 3px;
	border: 1px solid var(--dark-blue);
	border-radius: 9px;
	position: relative;

	.default-input,
	.default-select {
		position: relative;

		label {
			@mixin min-media 552 {
				position: absolute;
				top: 0;
				transform: translateY(-36px);
				padding-bottom: 0;
			}
		}
	}

	.default-input {
		label {
			margin-left: ac(-4px, 0px);
		}

		input {
			border: none;
			padding-left: ac(20px, 12px);
		}

		/*&:before {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 30px;
            background: var(--black);
            width: 1px;
        }*/

		@mixin min-media 1001 {
			width: 16.95%;
		}

		@mixin media 1000 {
			width: 100%;

			label {
				top: auto;
				transform: none;
				position: relative;
				padding-left: 10px;
			}

			input {
				border-bottom: 1px solid var(--dark-blue);
				border-radius: 0;

				&:focus {
					border-color: var(--red);
				}
			}
		}
	}

	.default-select {
		.choices__inner {
			border: none;
			padding-left: ac(15px, 8px);
			padding-right: ac(28px, 25px);
			border-radius: 0;

			&:before {
				position: absolute;
				content: "";
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				height: 30px;
				background: var(--black);
				width: 1px;
			}
		}

		.choices {
			margin-bottom: 0 !important;
		}

		.choices__list--dropdown,
		.choices__list[aria-expanded] {
			margin-top: 4px;
			max-height: 150px;
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: var(--red) var(--grey);
			border-radius: 0 0 9px 9px;
			padding-right: 2px;

			&::-webkit-scrollbar {
				width: 2px;
			}

			&::-webkit-scrollbar-track {
				background: var(--grey);
				border-radius: 0;
			}

			&::-webkit-scrollbar-thumb {
				background: var(--red);
				border-radius: 0;
			}

			@mixin mob-lg {
				margin-top: -1px;
			}
		}

		.is-flipped .choices__list--dropdown,
		.is-flipped .choices__list[aria-expanded] {
			margin-bottom: 3px;
			border-radius: 9px 9px 0 0;

			@mixin mob-lg {
				margin-bottom: -1px;
			}
		}

		.default-select__list {
			@mixin min-media 1001 {
				border-radius: 4px;
				clip-path: inset(0 0 0 0 round 4px);
				border: 1px solid var(--grey);
				padding: 4px;
				position: absolute;
				left: 0;
				z-index: 10;
				width: 98%;
				bottom: -4px;
				background: var(--white);
				transform: translateY(100%);
				overflow-y: auto;
				max-height: 118px;
				scrollbar-width: thin;
				scrollbar-color: var(--red) var(--grey);
				&::-webkit-scrollbar {
					width: 2px;
				}

				&::-webkit-scrollbar-track {
					background: var(--grey);
					border-radius: 0;
				}

				&::-webkit-scrollbar-thumb {
					background: var(--red);
					border-radius: 0;
				}

				.default-select__list-item {
					font-size: 12px;
				}
			}

			@mixin media 1000 {
				margin-top: 10px;
				max-height: 260px;
				overflow-y: auto;
				padding-right: 10px;
			}
		}

		@mixin min-media 1001 {
			&:nth-child(1) {
				width: 19.11%;
			}

			&:nth-child(2) {
				width: 18.92%;
			}

			&:nth-child(3) {
				width: 21.48%;
			}

			&:nth-child(4) {
				width: 14.88%;
			}
		}

		@mixin media 1000 {
			width: 49%;

			label {
				top: auto;
				transform: none;
				position: relative;
				padding-left: 10px;
			}

			.choices__inner {
				border-bottom: 1px solid var(--dark-blue);
				border-radius: 0;

				&:before {
					content: none;
				}
			}
		}

		.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
			max-height: none;
			overflow: visible;
		}

		&.not-select-item {
			.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
				.choices__item {
					&[data-value=" "] {
						display: none;
					}
				}
			}
		}
	}

	.btn {
		margin-left: auto;
		padding: 10.5px 13px;
		min-width: ac(84px, 80px);

		&.btn-clear {
			min-width: auto;
		}
	}

	.btn-clear {
		position: absolute;
		right: -6px;
		top: 50%;
		transform: translateY(-50%) translateX(100%);
	}

	@mixin media 1140 {
		.btn-clear {
			position: absolute;
			right: 3px;
			top: -6px;
			transform: translateY(-100%);
		}
	}

	@mixin media 1000 {
		flex-wrap: wrap;
		grid-row-gap: 18px;
		padding: 20px 16px;
		justify-content: space-between;
		align-items: flex-start;

		.btn-clear {
			position: relative;
			right: auto;
			top: auto;
			transform: none;
		}

		label {
			padding-left: 10px;
		}

		.btn {
			margin-left: auto;
			margin-right: auto;
			min-width: 175px;
		}
	}

	@mixin mob-lg {
		padding-top: 10px;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		.default-input {
			width: 100%;

			&:before {
				content: none;
			}

			input {
				border-bottom: 1px solid var(--dark-blue);
				border-radius: 0;
			}

			label {
				padding-left: 8px;
			}

			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}

		.default-select {
			width: 100%;

			label {
				padding-left: 8px;
			}

			.choices__inner {
				border-bottom: 1px solid var(--dark-blue);
				border-radius: 0;
			}

			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}

		.btn {
			width: 100%;
		}
	}
}

.is-safari {
	@mixin media 1000 {
		.first-form-container .default-select .default-select__list {
			&::-webkit-scrollbar {
				width: initial;
			}

			&::-webkit-scrollbar-track {
				background: initial;
				border-radius: initial;
			}

			&::-webkit-scrollbar-thumb {
				background: initial;
				border-radius: initial;
			}
		}
	}
}

.counter {
	display: flex;
	align-items: stretch;
	height: 45px;
	gap: ac(20px, 10px);

	.counter-btn {
		width: 45px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 21.5px;
		color: var(--red);
		line-height: 1;
		transition: color 0.25s ease, background-color 0.25s ease,
			border-color 0.25s ease, transform 0.25s ease;
		cursor: pointer;
		border: 1px solid var(--red);
		border-radius: 4px;

		&:hover {
			background: var(--red);
			color: var(--dark-blue);
		}

		&:active {
			transform: scale(0.8);
		}
	}

	input {
		padding: 5px;
		font-family: var(--font-main);
		font-weight: 500;
		font-size: 20px;
		line-height: 1;
		@mixin transparent-bg-input var(--dark-blue);
		width: 45px;
		height: 45px;
		text-align: center;
		background: var(--white);
		border-radius: 4px;
		border: 1px solid var(--white);
		transition: border-color 0.3s ease;

		&:focus {
			border-color: var(--red);
		}
	}
}

.checkboxes {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	gap: 15px;
}

.checkbox {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;

	.checkbox-box {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 15px;
		width: 15px;
		height: 15px;
		border: 1.5px solid var(--dark-blue);
		border-radius: 2px;
		margin-right: ac(9px, 5px);
		transition: all 0.3s ease;
		background: transparent;

		/*		i {
			font-size: 8px;
			color: var(--dark);
			opacity: 0;
			transition: opacity 0.25s ease, color 0.3s ease;
		}*/

		&:hover {
			border-color: var(--red);
			background-color: var(--grey);
		}
	}

	input {
		display: none;
	}

	.checkbox-text {
		font-family: var(--font-main);
		font-size: 11px;
		font-weight: 400;
		line-height: 120%;
		transition: all 0.3s ease;

		a {
			font: inherit;
			font-weight: 600;
			transition: all 0.25s ease;
			color: var(--dark-blue);
			text-decoration: underline;
			text-decoration-color: var(--transparent);

			&:hover {
				color: var(--red);
				text-decoration-color: var(--red);
			}
		}
	}

	input:checked ~ .checkbox-box {
		/*i {
			color: var(--dark);
			opacity: 1;
		}*/
		background-color: var(--red);
		border-color: var(--transparent);
	}

	/*&.dark-bg {
		.checkbox-box {
			border-color: var(--white);

			i {
				color: var(--white);
			}
		}

		input:checked ~ .checkbox-box {
			i {
				color: var(--white);
			}
		}

		.checkbox-text {
			background: var(--gradient-second);
			background-clip: text;
			-webkit-background-clip: text;
			transition: all 0.3s ease;
		}

		&:hover {
			.checkbox-box {
				border-color: var(--pink);
			}
		}
	}*/
}

.default-image-input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-left: ac(103px, 80px);
	position: relative;
	z-index: 1;
	min-height: ac(75px, 64px);

	&__image-container {
		width: ac(75px, 64px);
		height: ac(75px, 64px);
		position: absolute;
		top: 0;
		left: 0;

		input {
			position: absolute;
			opacity: 0;
			pointer-events: none;
			inset: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		position: relative;
		cursor: pointer;

		&:before {
			inset: 0;
			position: absolute;
			content: "";
			background: var(--light-grey);
			z-index: -1;
			border-radius: 50%;
			transform: scale(0.99);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: scale(0.99);
			border-radius: 50%;
			overflow: hidden;
		}

		&.not-border-type {
			img {
				border-radius: 0;
			}

			&:before {
				border-radius: 0;
			}
		}
	}

	&__btn {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: ac(25px, 24px);
		width: ac(25px, 24px);
		height: ac(25px, 24px);
		background: var(--red);
		border-radius: 50%;
		color: var(--white);
		transition: background-color 0.3s ease;
		cursor: pointer;
		bottom: 2px;
		right: 2px;
		z-index: 2;

		&:hover {
			background-color: var(--dark-blue);
		}
	}

	&__title {
		font-size: 14px;
		margin-bottom: ac(20px, 16px);
		font-weight: 700;
		line-height: 125%;
		font-family: var(--font-main);
		color: var(--dark-blue);
		text-transform: uppercase;
		cursor: pointer;
	}

	&__name {
		font-size: 14px;
		font-weight: 400;
		line-height: 125%;
		font-family: var(--font-main);
		color: var(--dark-blue);
		cursor: pointer;
	}
}

.default-file-input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;

	input {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	&__title {
		font-size: 14px;
		margin-bottom: ac(15px, 14px);
		font-weight: 700;
		line-height: 125%;
		font-family: var(--font-main);
		color: var(--dark-blue);
		text-transform: uppercase;
		cursor: pointer;
	}

	&__upload-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: ac(30px, 20px);

		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}

	&__upload-name {
		font-size: 14px;
		font-weight: 500;
		line-height: 125%;
		font-family: var(--font-main);
		color: var(--dark-blue);
		cursor: default;
		position: relative;
		z-index: 20;

		a {
			transition: all .3s ease;
			font: inherit;
			color: var(--red);
			cursor: pointer;
			text-decoration: underline;

			&:hover {
				color: var(--blue);
			}
		}
	}

	.btn.btn-red-border {
		min-height: ac(57px, 50px);
		min-width: ac(159px, 150px);
		border-color: var(--red);
		cursor: pointer;
	}

	&__upload-info {
		font-size: 11px;
		font-weight: 400;
		line-height: 125%;
		font-family: var(--font-main);
		color: var(--dark-blue);
	}

	&.drag-drop-type {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		gap: ac(50px, 20px);

		.default-file-input__title {
			margin-bottom: 0;
		}

		.default-file-input__upload-btn {
			margin-left: auto;
		}

		.default-file-input__upload-name {
			color: var(--red);
		}

		.default-file-input__upload-info {
			width: 100%;
			text-align: right;
			margin-top: 15px;
		}
	}
}

.upload-file-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;

	label {
		font-family: var(--font-main);
		font-size: 14px;
		line-height: 17px;
		font-weight: 700;
		color: var(--dark-blue);
		padding-bottom: 16px;
		text-transform: uppercase;
	}
}

.default-upload-file {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: ac(16px, 12px);
	padding-right: ac(16px, 12px);
	padding-top: 5px;
	padding-bottom: 5px !important;
	width: 100%;
	min-height: ac(50px, 42px);
	border-width: 1px;
	border-style: dashed;
	border-color: var(--dark-blue);
	border-radius: 9px;
	transition: border-color 0.25s ease;
	cursor: pointer;
	overflow: hidden;

	input {
		position: absolute;
		inset: 0;
		z-index: 2;
		cursor: pointer;
		opacity: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&__name {
		font-size: 14px;
		line-height: 28px;
		font-weight: 500;
		font-family: var(--font-main);
		color: var(--dark-blue);
		cursor: pointer;
		transition: all .3s ease;
	}

	&:hover {
		border-color: var(--blue);

		.default-upload-file {
			color: var(--red);
		}
	}
}

.project-popup {
	.checkbox .checkbox-text {
		color: var(--dark-blue);
	}

	.form__bottom {
		align-items: center;
	}

	.popup__title {
		font-family: var(--font-main);
	}
}

.crop {
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 100% !important;

	+ .crop {
		margin-top: 15px;
	}

	&__wrap {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-column-gap: 15px;
		grid-row-gap: 15px;

		@mixin mob-lg {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}
	&__input {
		label {
		}

		input {
			width: 100%;
			min-width: 100%;
		}

		select {
			width: 100%;
			min-width: 100%;
			cursor: pointer;
		}

		option {
			cursor: pointer;
		}
	}
	&__btn {
		margin: 0 auto;
		margin-top: 20px;
		width: max-content;

		i {
			margin-right: 5px;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
	}
	&__image {
		/*width: ac(360px, 300px) !important;
		height: ac(360px, 300px) !important;*/
		border-radius: 15px;
		overflow: hidden;
		margin: ac(-20px, 0px) auto 0 0;

		@mixin mob-lg {
			width: 100%;
			border-radius: 12px;
		}
	}
	&__select {


	}
}

.choices[data-type='select-multiple'] {
	cursor: pointer;
}


