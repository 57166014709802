.portal {
	overflow: hidden;
	max-width: 1440px;
	margin: 0 auto;

	.portal-close-bg {
		@mixin min-media 770 {
			display: none;
		}

		@mixin tab-sm {
			position: fixed;
			inset: 0;
			z-index: 40;
			background-color: var(--dark-blue);
			opacity: 0;
			transition: opacity 0.5s ease;
			pointer-events: none;
		}
	}

	.portal-header {
		padding-top: 15px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: 200;

		@mixin min-media 770 {
			display: none;
		}

		&__wrap {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.portal-header-burger {
			cursor: pointer;
			-webkit-tap-highlight-color: transparent;
			transition: transform 400ms;
			-moz-user-select: none;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;
			transform: scale(1.5);
			width: ac(60px, 40px) !important;
			&.active {
				transform: rotate(45deg) scale(1.5);

				.top {
					stroke-dashoffset: -68px;
				}

				.bottom {
					stroke-dashoffset: -68px;
				}
			}

			.line {
				fill: none;
				transition: stroke 0.3s ease, stroke-dasharray 400ms,
					stroke-dashoffset 400ms;
				stroke: var(--red);
				stroke-width: 3.5;
				stroke-linecap: round;
			}

			.top {
				stroke-dasharray: 40 121;
			}

			.bottom {
				stroke-dasharray: 40 121;
			}
		}
	}

	&__wrap {
		position: relative;
		z-index: 4;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		min-height: 100vh;
		min-height: calc(var(--vh, 1vh) * 100);
		height: auto;

		@mixin tab-sm {
			&.active {
				.portal-navigation {
					transform: translateX(0);
				}

				.portal-close-bg {
					opacity: 0.3;
					pointer-events: auto;
				}
			}
		}
	}

	.portal-navigation {
		position: fixed;
		left: 7.64vw;
		top: 0;
		bottom: 0;
		width: 20.38%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding-top: ac(60px, 40px);
		padding-bottom: 26px;
		max-width: 294px;

		@mixin min-media 1441 {
			left: calc((100vw - 1221px) / 2);
		}

		&__bg {
			right: 0;
			left: -7.64vw;
			top: 0;
			bottom: 0;
			background: var(--blue);
			z-index: -1;
			position: absolute;
			content: "";
			overflow: hidden;

			@mixin min-media 1441 {
				left: calc((-1) * (100vw - 1221px) / 2);
			}
		}

		&__decor {
			@mixin aspect-ratio 916, 1043;
			width: 63.61vw;
			position: absolute;
			top: -41px;
			right: ac(-102px, -10px);
			min-width: 800px;
			max-width: 1000px;
			opacity: 0.3;
			pointer-events: none;
		}

		&__logo {
			@mixin aspect-ratio 191, 39.69;
			width: ac(191px, 150px);
			cursor: pointer;
			transition: transform 0.3s ease;
			min-height: ac(39.69px, 32px);

			img {
				object-fit: contain;
				object-position: left;
			}

			&:hover {
				transform: scale(1.05);
			}

			&:not(:last-child) {
				margin-bottom: ac(80px, 60px);

				@mixin media-height 700 {
					margin-bottom: 40px;
				}
			}
		}

		&__text {
			margin-top: calc(ac(-119.31px, -60px) + 32px);
			margin-bottom: 40px;
			padding-right: ac(34px, 20px);
		}

		&__bottom-text {
			margin-top: auto;
			font-size: 12px;
			color: var(--white);
			max-width: 95%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			grid-column-gap: 4px;

			a {
				font: inherit;
				color: var(--white);
				text-decoration: underline;
				transition: all .3s ease;

				&:hover {
					color: var(--grey);
				}
			}

			&.with-login-reg {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				flex-direction: row;
			}
		}

		&__welcome {
			padding: 10px;
			border-radius: 9px;
			background: var(--white);
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: ac(18px, 12px);
			margin-bottom: 20px;
		}

		&__welcome-image {
			@mixin aspect-ratio 1, 1;
			width: ac(75px, 64px);
			clip-path: inset(0 0 0 0 round 50%);

			&.not-border-type {
				clip-path: none;
			}

			img {
				object-fit: contain;
			}
		}

		&__welcome-name {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			font-size: 14px;
			line-height: 1.15;
			min-height: calc((14px * 1.15) * 3);

			span {
				font-weight: 700;
			}
		}

		&__nav-container {
			width: 100%;
			flex: 1;
			padding-right: ac(34px, 20px);
			padding-bottom: 20px;
		}

		&__nav {
			width: 100%;
			max-height: 100%;

			@mixin min-media 770 {
				max-height: 45vh;
			}

			@mixin tab-sm {
				max-height: 50vh;
			}

			.simplebar-track.simplebar-vertical {
				width: 4px;
				background: transparent;
				border-radius: 2px;
				transform: translateX(8px);

				.simplebar-scrollbar {
					background: var(--grey);
					border-radius: 2px;

					&:before {
						content: none;
					}
				}
			}
		}

		&__link {
			width: 100%;
			opacity: 0.5;
			padding: ac(20px, 16px) ac(30px, 14px);
			border-radius: 9px;
			background: var(--white);
			transition: opacity 0.3s ease;
			cursor: pointer;
			font-size: 14px;
			font-weight: 700;
			line-height: 125%;
			text-transform: uppercase;
			font-family: var(--font-main);
			min-height: ac(57px, 42px);
			display: flex;
			align-items: center;
			justify-content: flex-start;
			color: var(--dark-blue);

			&:hover {
				opacity: 0.7;
			}

			&.active {
				opacity: 1;
			}

			&:not(:last-child) {
				margin-bottom: 10px;
			}

			@mixin mob {
				font-size: 12px;
			}
		}

		@mixin desk-sm {
			width: 25%;
		}

		@mixin tab-md {
			left: calc((100vw - 90vw) / 2.5);
		}

		@mixin tab-sm {
			left: calc((100vw - 90vw) / 2);
			width: 300px;
			transform: translateX(-120%);
			z-index: 50;
			transition: transform 0.5s ease;
			padding-right: 10px;
		}

		@mixin mob {
			width: 270px;
		}

		@mixin mob-sm {
			width: 250px;
		}
	}

	.portal-content {
		padding-top: ac(50px, 40px);
		padding-bottom: ac(100px, 64px);
		width: 66.21%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-left: auto;

		&.not-top-type {
			padding-top: ac(88px, 40px);
		}

		&:not(.not-top-type) {
			@mixin tab-sm {
				padding-top: 80px;
			}
		}

		&__top {
			min-height: 41px;
			padding-bottom: ac(75px, 34px);
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			align-items: center;
			gap: ac(20px, 15px);
			margin-left: auto;

			.btn.btn-medium {
				padding: 10px 18px;
			}
		}

		&__container {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			.default-image-input {
				&:not(:last-child) {
					margin-bottom: ac(30px, 24px);
				}
			}

			.form {
				width: 100%;
			}

			&:not(:last-child) {
				margin-bottom: ac(83px, 32px);
			}

			.buttons {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-wrap: wrap;
				gap: ac(40px, 20px);
			}

			.buttons-links {
				margin-top: ac(-3px, 0px);
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex-wrap: wrap;
				gap: ac(30px, 20px);
				margin-left: auto;
			}

			.checkbox {
				.checkbox-text {
					font-size: 14px;
				}
			}

			.checkboxes {
				margin-top: ac(-2px, 0px);
			}

			.default-file-input.drag-drop-type {
				margin-top: ac(21px, 10px);
				width: 100%;
				margin-bottom: ac(40px, 32px);
				grid-row-gap: ac(0px, 15px);
			}
		}

		&__big-title {
			margin-top: ac(28px, 0px);
			font-size: ac(60px, 36px);
			font-weight: 800;
			line-height: 121.9%;
			margin-bottom: ac(35px, 28px);
		}

		&__title {
			color: var(--red);
			font-size: ac(20px, 18px);
			font-weight: 800;
			margin-bottom: ac(28px, 24px);
		}

		&__small-title {
			color: var(--red);
			font-size: ac(18px, 16px);
			font-weight: 600;
			margin-bottom: ac(25px, 20px);

			&:not(:first-child) {
				margin-top: ac(40px, 24px);
			}
		}

		&__label-title {
			font-size: 14px;
			font-weight: 700;
			margin-bottom: ac(22px, 20px);
			text-transform: uppercase;

			&:not(:first-child) {
				margin-top: ac(40px, 24px);
			}
		}

		&__applications {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			.line-decor {
				width: 62.67%;
				margin-bottom: ac(50px, 32px);
			}
		}

		&__applications-title {
			margin-bottom: 20px;
		}

		&__applications-list {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}

		&__applications-item {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			gap: ac(40px, 18px);

			&:not(:last-child) {
				margin-bottom: ac(27.5px, 24px);
			}

			@mixin mob-xl {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
			}
		}

		&__applications-names {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: ac(20px, 18px);
			flex: 1;
		}

		&__applications-name-file {
			font-size: 14px;
			font-weight: 400;
			line-height: 1.1;
			color: var(--red);
		}

		&__applications-name {
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 1.1;
		}

		&__applications-buttons {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			gap: ac(20px, 18px);
		}

		&__bottom {
			margin-top: auto;
			margin-left: auto;
			display: flex;
			justify-content: flex-end;
			flex-wrap: wrap;
			align-items: center;
			gap: ac(20px, 15px);

			@mixin mob {
				margin-left: 0;
				width: 100%;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				.btn {
					min-width: 200px;
				}
			}
		}

		&__jobs {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;

			.job-card {
				width: 100%;
				&:not(:last-child) {
					margin-bottom: ac(20px, 16px);
				}
			}
		}

		@mixin tab-sm {
			width: 100%;
		}
	}

	.btn {
		min-height: 41px;
	}
}

.portal {

	.jconfirm-title {
		color: var(--dark-blue);
		font-family: var(--font-main);
		font-size: ac(24px, 20px);
		font-weight: 600;
		line-height: ac(24px, 22px);
	}

	.jconfirm-content {
		div {
			color: var(--dark-blue);
			font-family: var(--font-main);
			font-size: ac(16px, 14px);
			font-weight: 400;
			line-height: ac(25px, 20px);
		}
	}

	.jconfirm .jconfirm-box.jconfirm-type-red .jconfirm-title-c .jconfirm-icon-c {
		color: var(--red) !important;
	}

	.jconfirm-box.jconfirm-type-red {
		border-top-color: var(--red) !important;
		animation: none !important;

		@mixin media 1100 {
			width: 300px !important;
			max-width: 91% !important;
		}
	}

	.jconfirm.jconfirm-modern .jconfirm-box .jconfirm-buttons {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.jconfirm.jconfirm-modern .jconfirm-box .jconfirm-buttons button {

		background: var(--red);
		padding: 7px 18px;
		position: relative;
		overflow: hidden;
		border: 2px solid var(--red);
		border-radius: 9px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
		color: var(--white);
		z-index: 2;
		text-transform: uppercase;
		transition: all 0.3s ease;
		font-family: var(--font-main);

		&:before {
			position: absolute;
			inset: 0;
			content: "";
			background: var(--white);
			opacity: 0;
			border-radius: 100px;
			transition: all 0.3s ease;
			z-index: -1;
			transform: scale(0) rotate(-14deg);
		}

		&:hover {
			transition: border-color 0.25s ease 0.15s, transform 0.25s ease;
			color: var(--red);

			&:before {
				transform: scale(1) rotate(0deg);
				opacity: 1;
				border-radius: 0;
			}
		}

		&.btn-default {
			border-color: var(--dark-blue);
			background: var(--transparent);
			border-width: 1px;
			padding-left: 19px;
			padding-right: 19px;
			color: var(--dark-blue);

			&:hover {
				border-color: var(--transparent);
				color: var(--white);

				&:before {
					transform: scale(1) rotate(0deg);
					opacity: 1;
					border-radius: 0;
					background-color: var(--blue);
				}
			}
		}
	}
}

.portal-navigation__bottom-text {
	margin-top: auto;
	/*font-size: 12px;*/
	color: var(--white);
	max-width: 95%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	grid-column-gap: 4px;
	grid-row-gap: 4px;

	a {
		font: inherit;
		color: var(--white);
		text-decoration: underline;
		transition: all .3s ease;

		&:hover {
			color: var(--grey);
		}
	}

	&.with-login-reg {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		flex-direction: row;
	}
}
