.form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__grid {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-column-gap: 15px;
		grid-row-gap: 15px;

		@mixin mob-lg {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}

	&__bottom {
		padding-top: ac(25px, 22px);
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: flex-start;
		gap: 20px;

		.btn {
			margin-left: auto;
		}
	}

	&__bottom-text {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		p {
			font-family: var(--font-main);
			font-size: 11px;
			font-weight: 400;
			line-height: 120%;
			transition: all 0.3s ease;

			a {
				font: inherit;
				font-weight: 600;
				transition: all 0.25s ease;
				color: var(--dark-blue);
				text-decoration: underline;
				text-decoration-color: var(--transparent);

				&:hover {
					color: var(--red);
					text-decoration-color: var(--red);
				}
			}
		}

		@mixin mob-lg {
			justify-content: flex-start;
		}
	}

	.default-input {
		label {
			padding-bottom: 16px;
		}

		input {
			transition: all 0.25s ease;
			min-height: ac(50px, 42px);
			padding-left: ac(30px, 16px);
			padding-right: ac(30px, 16px);
			@mixin transparent-bg-input var(--dark-blue);

			&:focus {
				border-color: var(--red);
				box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
			}
		}

		textarea {
			height: auto;
			transition: all 0.25s ease;
			padding-left: ac(30px, 16px);
			padding-right: ac(30px, 16px);

			&:focus {
				border-color: var(--red);
				box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
			}
		}
	}

	.default-select {
		label {
			padding-bottom: 16px;
		}

		.choices__inner {
			height: ac(50px, 42px);
			padding-left: ac(30px, 16px);
			padding-right: ac(30px, 16px);
		}

		.choices {
			&[data-type*="select-one"]::after {
				right: ac(34px, 16px);
				transform: rotate(45deg) scale(2);
			}

			&.is-open {
				&:after {
					transform: rotate(-135deg) scale(2);
				}
			}
		}
	}
}
