.job-result-section {
	margin-top: ac(-99px, -60px);
	padding-bottom: ac(60px, 32px);
	position: relative;
	z-index: 10;

	&__form {
		width: 100%;
		background: var(--white);
		border-radius: 9px;
		padding-top: ac(83px, 60px);
		padding-bottom: ac(50px, 40px);
		padding-left: ac(103px, 40px);
		padding-right: ac(103px, 40px);
		max-width: ac(1221px, 880px);
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
		margin-bottom: ac(70px, 40px);

		@mixin desk-sm {
			padding-left: 54px;
			padding-right: 54px;
		}

		@mixin media 1140 {
			padding-left: 20px;
			padding-right: 20px;
		}

		@mixin media 1000 {
			padding-top: ac(50px, 40px);
		}

		@mixin mob-xl {
			padding-left: 24px;
			padding-right: 24px;
		}

		@mixin mob-lg {
			padding-top: 40px;
		}

		@mixin mob-sm {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		max-width: ac(809px, 700px);
		margin: 0 auto;
	}

	&__top {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&:not(:last-child) {
			margin-bottom: ac(38px, 24px);
		}

		@mixin mob-xl {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&__create-btn {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 8px;
		border-radius: 9px;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
		transition: all 0.4s ease;
		background: var(--white);

		span {
			color: var(--dark-blue);
			font-family: var(--font-main);
			font-size: 14px;
			text-transform: uppercase;
			line-height: 1;
			font-weight: 700;
			padding-left: ac(22px, 10px);
			margin-right: ac(30px, 16px);
			transition: all 0.3s ease;
		}

		i {
			width: 31px;
			height: 31px;
			background: var(--red);
			border-radius: 9px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--white);
			font-size: 12px;
			transition: all 0.3s ease;
		}

		&:hover {
			background: var(--blue);

			span {
				color: var(--white);
			}

			i {
				background: var(--white);
				color: var(--dark-blue);
			}
		}
	}

	&__sort {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		label {
			cursor: pointer;
			font-family: var(--font-main);
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
		}

		.default-select {
			.choices__inner {
				border: none;
				padding-left: 18px;
				padding-right: 15px;
				font-size: 14px;
				font-weight: 500;

				.choices__item {
					padding-right: 0;
				}
			}

			.choices[data-type*="select-one"] {
				&:after {
					right: 4px;
				}
			}

			.choices[data-type*="select-one"] .choices__input {
				display: none;
			}

			.choices__list[aria-expanded] {
				.choices__item {
					&:first-child {
						display: none;
					}
				}
			}
		}

		@mixin mob-xl {
			&:last-child {
				margin-top: 10px;
				margin-left: auto;
			}
		}
	}

	&__result {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		.job-card {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: ac(20.4px, 16px);
			}
		}

		&:not(:last-child) {
			margin-bottom: ac(60px, 32px);
		}
	}

	&__result-title {
		width: 100%;
		text-align: center;
		color: var(--dark-blue);

		&:not(:last-child) {
			margin-bottom: ac(16px, 12px);
		}
	}
}
