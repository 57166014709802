.btn {
	background: var(--red);
	padding: 7px 18px;
	position: relative;
	overflow: hidden;
	border: 2px solid var(--red);
	border-radius: 9px;
	transition: border-color 0.25s ease, transform 0.25s ease;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	&:before {
		position: absolute;
		inset: 0;
		content: "";
		background: var(--white);
		opacity: 0;
		border-radius: 100px;
		transition: all 0.3s ease;
		z-index: 1;
		transform: scale(0) rotate(-14deg);
	}

	span,
	div {
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
		color: var(--white);
		position: relative;
		z-index: 2;
		text-transform: uppercase;
		transition: all 0.3s ease;
		font-family: var(--font-main);
	}

	&:hover {
		transition: border-color 0.25s ease 0.15s, transform 0.25s ease;
		span,
		div {
			color: var(--red);
		}
		&:before {
			transform: scale(1) rotate(0deg);
			opacity: 1;
			border-radius: 0;
		}
	}

	&:active {
		border-color: var(--grey);
		transform: scale(0.9);
	}

	&.btn-clear {
		/*border-radius: 50%;*/
		padding: 5px;
		width: 42px;
		height: 42px;

		i {
			font-size: 16px;
			transition: all .3s ease;
			color: var(--white);
			position: relative;
			z-index: 5;
		}

		&:hover {
			i {
				color: var(--red);
				transform: rotate(180deg);
			}
		}
	}

	&.btn-medium {
		padding: 10px 9px;
	}

	&.btn-big {
		padding: ac(18px, 16px) ac(30px, 24px);
		transition: all .3s ease;

		&.is-small {

			@mixin media 1650 {
				transform: translateX(calc(100% - ac(80px, 40px)));

				&:hover {
					transform: translateX(0);
				}
			}

		}
	}

	&.btn-border {
		border-color: var(--white);
		background: var(--transparent);
		border-width: 1px;

		&:hover {
			border-color: var(--transparent);
			span,
			div {
				color: var(--red);
			}
			&:before {
				transform: scale(1) rotate(0deg);
				opacity: 1;
				border-radius: 0;
			}
		}
	}

	&.btn-red-border {
		border-color: var(--red);
		background: var(--transparent);
		border-width: 1px;

		span {
			color: var(--red);
		}

		&:before {
			transform: scale(0) rotate(14deg);
		}

		&:hover {
			border-color: var(--transparent);
			span,
			div {
				color: var(--white);
			}
			&:before {
				transform: scale(1) rotate(0deg);
				opacity: 1;
				border-radius: 0;
				background-color: var(--blue);
			}
		}
	}

	&.btn-dark-border {
		border-color: var(--dark-blue);
		background: var(--transparent);
		border-width: 1px;
		padding-left: 19px;
		padding-right: 19px;

		span {
			color: var(--dark-blue);
		}

		&:before {
			transform: scale(0) rotate(14deg);
		}

		&:hover {
			border-color: var(--transparent);
			span,
			div {
				color: var(--white);
			}
			&:before {
				transform: scale(1) rotate(0deg);
				opacity: 1;
				border-radius: 0;
				background-color: var(--blue);
			}
		}
	}

	&.btn-dark {
		background: var(--dark-blue);
		border-color: var(--dark-blue);
		border-width: 1px;

		&:hover {
			border-color: var(--transparent);
			span,
			div {
				color: var(--dark-blue);
			}
			&:before {
				transform: scale(1) rotate(0deg);
				opacity: 1;
				border-radius: 0;
			}
		}
	}

	&.white-btn {
		background: var(--white);
		border-color: var(--white);

		span {
			color: var(--dark-blue);
		}

		&:before {
			background: var(--blue);
			transform: scale(0) rotate(14deg);
		}

		&:hover {
			span,
			div {
				color: var(--white);
			}
			&:before {
				transform: scale(1) rotate(0deg);
			}
		}
	}
}

.link {
	padding: 5px 0;
	cursor: pointer;
	color: var(--red);
	transition: color 0.3s ease;
	position: relative;
	z-index: 1;
	font-size: 14px;
	line-height: 1;
	font-weight: 700;
	font-family: var(--font-main);
	text-transform: uppercase;

	&:before {
		content: "";
		position: absolute;
		left: 50%;
		bottom: 0;
		height: 1px;
		background: var(--red);
		transform: translateX(-50%);
		width: 100%;
		transition: width 0.3s ease, background-color 0.3s ease;
	}

	&:hover {
		color: var(--blue);

		&:before {
			background-color: var(--blue);
			width: calc(100% - 30px);
		}
	}

	&.not-line-link {
		font-size: 11px;
		&:before {
			width: 0;
			right: 0;
			left: auto;
			transform: none;
		}

		&:hover {
			&:before {
				left: 0;
				right: auto;
				width: 100%;
			}
		}
	}
}

.socials {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: ac(31px, 20px)
}

.social-link {
	display: flex;
	justify-content: center;
	align-items: center;

	i {
		font-size: ac(24px, 22px);
		color: var(--white);
		transition: color .25s ease;
	}

	&:hover {
		i {
			color: var(--red);
		}
	}
}
