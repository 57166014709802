@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap";

:root {
	--black: #000000;
	--bg-second: #343434;
	--main: #3cc5fb;
	--white: #ffffff;
	--primary: #ffde33;
	--secondary: #088ded;

	--dark-blue: #0c1a31;
	--blue: #243850;
	--red: #b82929;
	--grey: #868d98;
	--light-grey: #d9d9d9;
	--transparent: color-mod(var(--white) a(0%));

	--font-main: "Montserrat", sans-serif;

	/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
	--mobile-menu-start-point: 1160;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1160px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1160px);
