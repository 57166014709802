h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a {
	font-family: var(--font-main);
	color: var(--dark-blue);
}

.dark-bg {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	a {
		color: var(--white);
	}
}

h1 {
	font-size: ac(60px, 36px);
	font-weight: 800;
	line-height: 121.9%;

	@mixin mob-sm {
		font-size: 30px;
	}

	a {
		font: inherit;
		text-decoration: none;
		transition: all .3s ease;

		&:hover {
			color: var(--blue);
		}
	}
}

h2 {
	font-size: ac(40px, 32px);
	font-weight: 800;
	line-height: 120%;

	@mixin mob-sm {
		font-size: 26px;
	}

	a {
		font: inherit;
		text-decoration: none;
		transition: all .3s ease;

		&:hover {
			color: var(--blue);
		}
	}
}

h3 {
	font-size: ac(20px, 18px);
	font-weight: 600;
	line-height: ac(24px, 22px);

	a {
		font: inherit;
		text-decoration: none;
		transition: all .3s ease;

		&:hover {
			color: var(--blue);
		}
	}
}

h4 {
	font-size: 16px;
	font-weight: 700;
	line-height: 125%;

	a {
		font: inherit;
		text-decoration: none;
		transition: all .3s ease;

		&:hover {
			color: var(--blue);
		}
	}
}

h5 {
	font-size: 12px;
	font-weight: 700;
	line-height: 1.2;
}

h6 {
	font-size: 12px;
	font-weight: 600;
	line-height: 1.2;
}

p {
	font-size: ac(16px, 14px);
	font-weight: 400;
	line-height: ac(25px, 20px);
}

li {
	font-size: ac(16px, 14px);
	font-weight: 400;
	line-height: ac(25px, 20px);
}

a {
	font-size: ac(16px, 14px);
	font-weight: 500;
	color: var(--red);
	display: inline-block;
	line-height: 1.5;
}

.content-element {
	font-size: ac(16px, 14px);
	font-weight: 400;
	line-height: ac(25px, 20px);
	font-family: var(--font-main);

	&.dark-bg {
		color: var(--white);
	}

	h2,
	h3 {
		&:not(:last-child) {
			margin-bottom: ac(20px, 16px);
		}

		&:not(:first-child) {
			padding-top: ac(20px, 16px);
		}
	}

	h4 {
		text-transform: uppercase;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&:not(:first-child) {
			padding-top: 15px;
		}
	}

	h2 {
		font-size: ac(30px, 26px);

		@mixin mob-sm {
			font-size: 24px;
		}
	}

	p, li {
		a {
			text-decoration: underline;
			transition: all .3s ease;

			&:hover {
				color: var(--red);
			}
		}
	}

	p,
	ul,
	ol {
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}

	ul {
		display: grid;
		gap: ac(0px, 2px);

		li {
			padding-left: ac(24px, 20px);
			position: relative;

			&:before {
				content: "";
				width: 3px;
				height: 3px;
				position: absolute;
				left: calc(ac(24px, 20px) / 3);
				top: 10px;
				background: var(--dark-blue);
				border-radius: 50%;
			}
		}
	}

	ol {
		list-style-type: none;
		counter-reset: num;
		gap: ac(0px, 2px);
		display: grid;

		li {
			/*font-size: ac(18px, 16px);*/
			position: relative;
			padding-left: ac(24px, 20px);

			&:before {
				position: absolute;
				color: var(--dark-blue);
				top: 0;
				left: ac(6px, 4px);
				font: inherit;
				content: counter(num) ".";
				counter-increment: num;
			}
		}
	}

	blockquote {
		padding-left: ac(24px, 20px);
		border-left: 1px solid var(--blue);

		p {
			font-style: italic;
		}
	}

	&.big-type {
		p {
			font-size: ac(24px, 18px);
			font-weight: 400;
			line-height: ac(29px, 22px);
		}
	}
}
